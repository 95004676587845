import styled from "@emotion/styled";

const Row = styled.div`
  width: 90%;
  margin: 15px auto;
  position: relative;
  border-bottom: 2px solid #b2d1cc;
  padding: 10px;
  padding: 8px 4px;
`;

export default Row;
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-dropdown/style.css";
import "./Product.css";

import logo from "../../Logos/Soapstand-icon-wordmark-teal.png";
import logoIcon from "../../Logos/Soapstand-icon.png";
import soapIcon from "../../Assets/SoapIcon.png";
import footerImage from "../../Assets/Soapstand-previewCover.png";
import footerArrow from "../../Assets/Soapstand-previewArrow.png";
import footerNotify from "../../Assets/Soapstand-previewNotify.png";
import axios from "axios";
import authHeader from "../Authentication/auth-header";

class PreviewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewProduct: false,
    };
  }

  updateProduct = (event) => {
    event.preventDefault();

    const confirmed = window.confirm("Please confirm station update!");
    if (confirmed) {
      let payload = {
        soapstand_product_serial: this.props.localProductId,
        productId: this.props.productId,
      };
      axios({
        method: "post",
        url: `/api/product/update_product_with_soapstandId`,
        data: payload,
        headers: authHeader(),
      })
        .then((response) => {
          alert("Product Information Updated.");
          window.location.reload(false);
        })
        .catch((err) => {
          alert(
            "Some error occured!! Product will not be updated at this time"
          );
        });
    }
  };
  render() {
    return (
      <div className="popup-box preview-popup">
        {this.props.localproducts[this.props.localProductId] != undefined ? (
          <>
            <div className="preview-box">
              <div className="previewProduct">
                <span
                  className="close-icon preview-close"
                  onClick={this.props.handleClose}
                >
                  x
                </span>
                <img
                  className="previewProduct-logo"
                  src={logo}
                  alt={logo}
                ></img>
                <div className="previewProduct-productName">
                  {this.props.localproducts[this.props.localProductId][
                    "Product Name on UI"
                  ]
                    ? this.props.localproducts[this.props.localProductId][
                        "Product Name on UI"
                      ].replace("<br>", "")
                    : "Information Unavailable"}
                </div>
                <div className="previewProduct-productDescription">
                  {this.props.localproducts[this.props.localProductId][
                    "Product Description on UI"
                  ]
                    ? this.props.localproducts[this.props.localProductId][
                        "Product Description on UI"
                      ]
                    : null}
                </div>
                <div className="previewProducct-middleSection">
                  <div className="previewProduct-imageContainer">
                    {(() => {
                      if (
                        this.props.localproducts[this.props.localProductId][
                          "URL"
                        ] != null
                      ) {
                        return (
                          <img
                            className="previewProduct-image"
                            src={
                              this.props.localproducts[
                                this.props.localProductId
                              ]["URL"]
                            }
                          ></img>
                        );
                      }
                      return (
                        <img
                          className="previewProduct-imageUnavailable"
                          src={logoIcon}
                        ></img>
                      );
                    })()}
                    <img
                      className="previewPorduct-imageIcon"
                      src={soapIcon}
                      alt={soapIcon}
                    ></img>
                  </div>
                  <div className="privewProduct-quantityContainer">
                    <div className="previewProduct-quantity">
                      Select Quantity
                    </div>
                    <div className="previewProduct-boxContainer">
                      <div className="quantity-box box-01"></div>
                      <div className="quantity-box box-02"></div>
                    </div>
                  </div>
                </div>
                <div className="previewProduct-ingredientsContainer">
                  <div className="previewProduct-ingredients">
                    Ingredients:{" "}
                  </div>
                  <div className="previewProduct-ingredientsContent">
                    {this.props.localproducts[this.props.localProductId][
                      "Ingredients on UI"
                    ]
                      ? this.props.localproducts[this.props.localProductId][
                          "Ingredients on UI"
                        ]
                      : "Information Unavailable"}
                  </div>
                </div>
              </div>
              <div className="previewProduct-footerContainer">
                {this.props.selectButton && (
                  <button
                    className="btn btn-main btn-previewConfirm"
                    onClick={this.updateProduct}
                  >
                    Confirm
                  </button>
                )}
                <img
                  className="previewProduct-footerImage"
                  src={footerImage}
                  alt={footerImage}
                ></img>
                <img
                  className="previewProduct-footerArrow"
                  src={footerArrow}
                  alt={footerArrow}
                ></img>
                <div className="previewProduct-NotifyContainer">
                  <img
                    className="previewProduct-footerNotify"
                    src={footerNotify}
                    alt={footerNotify}
                  ></img>
                  <div className="previewProduct-notifyText">Notify Us</div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="preview-box">
            <div className="previewProduct">
              <span
                className="close-icon preview-close"
                onClick={this.props.handleClose}
              >
                x
              </span>
              <img className="previewProduct-logo" src={logo} alt={logo}></img>
              <div className="previewProduct-productName notAvailable">
                No preview available
              </div>
            </div>
            <div className="previewProduct-footerContainer">
              <img
                className="previewProduct-footerImage"
                src={footerImage}
                alt={footerImage}
              ></img>
              <img
                className="previewProduct-footerArrow"
                src={footerArrow}
                alt={footerArrow}
              ></img>
              <div className="previewProduct-NotifyContainer">
                <img
                  className="previewProduct-footerNotify"
                  src={footerNotify}
                  alt={footerNotify}
                ></img>
                <div className="previewProduct-notifyText">Notify Us</div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PreviewProduct;

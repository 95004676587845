import './App.css';
import Order from './Components/Order/AllOrders'
import Logout from './Components/Authentication/Logout';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import StripeContainer from './Payments/StripeComponent';
import AuthService from './Components/Authentication/AuthService';
import { Component } from 'react';
import Homepage from './Components/HomePage/Homepage'
import Header from './Components/Header/Header';
import HeaderAelen from './Components/Header/HeaderAelen';
import AddStation from './Components/Station/AddStation';
import Upload from './Components/Product/Upload';
import Ticket from './Components/Tickets/Ticket';
import CouponManager from './Components/Coupons/couponManager';
import ChangePasswordForm from './Components/Authentication/ChangePassword'
import OfflinePaymentPage from './Payments/OfflinePaymentPage'

class App extends Component{
  constructor(props){
    super(props);
    this.logout = this.logout.bind(this);
    this.state = {
      showUser: false,
      showAdmin: false,
      showClient: false,
      showSubscriber: false,
      showDeveloper: false,
      currentUser: undefined
    }
  }

  async componentDidMount(){
    const user =await AuthService.getCurrentUser();
    if(user){
      this.setState({ 
        currentUser: user,
        showAdmin: user.permissions.includes('admin'),
        showDeveloper: user.permissions.includes('developer'),
        showClient: user.permissions.includes('client'),
        showSubscriber: user.permissions.includes('Subscriber')

      })
    }
  }
  logout(){

    AuthService.logout()
  }
  render(){
    const {currentUser, showAdmin, showDeveloper} = this.state
    return(
      <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/payment" element={<><Header/><StripeContainer/></>} />
        <Route path="/paymentAelen" element={<><HeaderAelen/><StripeContainer/></>} />
        <Route path="/offlinePayment" element={<><HeaderAelen/><OfflinePaymentPage/></>} />

        <Route path="/upload" element={<Upload />} />
        <Route path="/ticket" element={<><Header/><Ticket userId={currentUser} /></>} />
        <Route path="/orders" element={<><Homepage /><Order info={{view: showAdmin ? "admin" : "client", userId: currentUser }}/></>} />
        <Route path="/changePassword" element={<><Homepage /><ChangePasswordForm /></>} />
        <Route path="/stations" element={<><Homepage /></>} />
        <Route path="/addStation" element={showDeveloper && <><Homepage /><AddStation info={{view: "developer", userId: currentUser }}/></>} />
        <Route path="/logout" element={<><Homepage /><Logout /></>} />
        <Route path="/coupon" element={<><Homepage /><CouponManager /></>} />
        <Route path="/" element={<><Homepage pageType="initial" /></>} />
      </Routes>
    </BrowserRouter>

    </div>
    )
  }
}

export default App

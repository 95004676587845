import React from "react";
import { Icon } from "semantic-ui-react";
import Product from "../Product/Product";
import Dispense from "../Dispense/Dispense";
import axios from "axios";
import ShowProductDetails from "../Product/ShowProductDetails";
import Upload from "../Product/Upload";
import "./Station.css";
import StationWifi from "../Station/StationWifi";
import CleaningLog from "../Station/CleaningLog";

class StationInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showStations: true,
      addProduct: false,
      showProductIds: false,
      uploadImageState: false,
      currentSort: "down",
      stationWifi: false,
      cleaningLog: false,
      editProductsMode: false,
    };
  }

  toggleEditProductsMode = () => {
    this.setState({ editProductsMode: !this.state.editProductsMode });
  };

  stationWifi = () => {
    this.setState({ stationWifi: !this.state.stationWifi });
  };

  cleaningLog = () => {
    this.setState({ cleaningLog: !this.state.cleaningLog });
  };

  show_transactions = (orders, serialNumber, location, launchDate) => {
    this.props.transactionFn(orders, serialNumber, location, launchDate);
  };

  togglePopup = () => {
    this.setState({ addProduct: !this.state.addProduct });
  };

  showPids = () => {
    this.setState({ showProductIds: !this.state.showProductIds });
  };

  uploadImage = () => {
    this.setState({ uploadImageState: !this.state.uploadImageState });
  };

  getTransactionsForStation = (serialNumber, location, launchDate) => {
    axios({
      method: "get",
      url: `/api/order/get_all_machine_orders/${serialNumber}`,
    })
      .then((response) => {
        this.show_transactions(
          response.data,
          location,
          launchDate,
          serialNumber
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  updateStationDatabase = async () => {
    const confirmAccepted = window.confirm(
      "Are you sure about making changes to the database?"
    );
    if (confirmAccepted) {
      axios({
        method: "post",
        url: "/api/machine/updateStationDb",
        data: { port: this.props.machine.aws_port },
      })
        .then((response) => {
          alert("Database updated");
        })
        .catch((err) => {
          alert("Database update failed!");
        });
    }
  };
  render() {
    let location = this.props.machine.location.split(",");
    let products = this.props.machine.Products;
    const sortTypes = {
      up: {
        class: "sort-up",
        fn: (a, b) => a.slot - b.slot,
      },
      down: {
        class: "sort-down",
        fn: (a, b) => b.slot - a.slot,
      },
    };

    return (
      <div className={`stationblock border rounded py1 target-${this.props.machine.serialNumber}`}>
        <table className="tableStation">
          <thead>
            <tr class="Heading">
              <th>Station Status</th>
              <th>Serial Number</th>
              <th>Location</th>
              <th>AWS Port number</th>
              <th>Software version</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {this.props.machine.active ? (
                  <div className="online">
                    <Icon name="check circle" color="green" /> Online
                  </div>
                ) : (
                  <div className="online">
                    <Icon name="dot circle" color="red" /> Offline
                  </div>
                )}
              </td>
              <td>{this.props.machine.serialNumber}</td>
              <td>{this.props.machine.location}</td>
              <td>{this.props.machine.aws_port}</td>
              <td>{this.props.machine.software_version}</td>
            </tr>
          </tbody>
        </table>
        <>
          <div
            className="stationContainer flex lg-py3 clearfix"
            key={this.props.machine.serialNumber}
          >
            {this.state.showProductIds && (
              <ShowProductDetails
                handleClose={this.showPids}
                products={products}
              />
            )}
            <div class="col-6 lg-col-7 rounded pt2 lg-py2 productInfo">
              <p className=" px1 title">Product</p>
              {products.sort(sortTypes["up"].fn).map((product, index) => {
                if (product["active"] == true) {
                  return (
                    <Product
                      product={product}
                      machine={this.props.machine}
                      index={product["slot"]}
                      key={index}
                      user={this.props.user}
                      rolesArray={this.props.rolesArray}
                      role={this.props.role}
                      editProductsMode={this.state.editProductsMode}
                      toggleEditProductsMode={this.toggleEditProductsMode}
                    />
                  );
                }
              })}
            </div>
            <div class="col-6 lg-col-5 rounded  pb2 lg-py2 dispense">
              <p className="px1 title">Dispensed</p>
              <div class="clearfix">
                <div className="col-12 dispenseInfo">
                  <Dispense
                    orders={this.props.machine.Orders}
                    launchDate={this.props.machine.launchDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
        <div className="buttonContainer">
          {this.state.stationWifi && (
            <StationWifi
              handleClose={this.stationWifi}
              stationId={this.props.machine.serialNumber}
            />
          )}
          <button
            className="px3 mt2 btn-main"
            type="button"
            onClick={this.stationWifi}
          >
            Status
          </button>


          {this.state.editProductsMode ? (
            <button
              className="px3 mt2 btn-main"
              onClick={() => {
                this.toggleEditProductsMode();
              }}
            >
              Disable Editing
            </button>
          ) : (
            <button
              className="px3 mt2 btn-main"
              onClick={() => {
                this.toggleEditProductsMode();
              }}
            >
              Enable Editing
            </button>
          )}
          {this.props.user.permissions.includes("developer") && (
            <>

              <button
                className="px3 mt2 btn-main"
                onClick={() => {
                  this.showPids();
                }}
              >
                Show Pids
              </button>
            </>
          )}
          {this.state.cleaningLog && (
            <CleaningLog
              handleClose={this.cleaningLog}
              stationId={this.props.machine.serialNumber}
            />
          )}

          <button
            className="px3 mt2 btn-main"
            onClick={() => {
              this.show_transactions(
                this.props.machine.Orders,
                this.props.machine.location,
                this.props.machine.launchDate,
                this.props.machine.serialNumber
              );
            }}
          >
            View Transactions
          </button>
        </div>
      </div>
    );
  }
}

export default StationInfo;

import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Login.css";
import AuthService from "./AuthService";
import { useNavigate } from 'react-router-dom';
import OTPVerification from "./OTP";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showOTP, setShowOTP] = useState(false);

  const handleForgotPassword = () => {
    setShowOTP((prev) => !prev);
  };

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    AuthService.login(email, password).then(
      () => {
        if (props.type === "Subscription") {
          // navigate(`/subscriptions${history.location.search}`);
        } else {
          navigate("/");
          window.location.reload();
        }
      },
      (error) => {
        alert(error.response.data.message);
      }
    );
  }

  return (
    <div>
      {showOTP ? (
        <OTPVerification />
      ) : (
        <Form className="Login" onSubmit={handleSubmit}>
          <Form.Group size="lg" controlId="email" id="email">
            <Form.Control
              autoFocus
              type="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password" id="password">
            <Form.Control
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button
            block
            size="lg"
            type="submit"
            disabled={!validateForm()}
            id="login-button"
          >
            Login
          </Button>
        </Form>
      )}

      {showOTP ? (
        <Button type="submit" id="reset-button" onClick={handleForgotPassword}>
          Return to Login
        </Button>
      ) : (
        <Button type="submit" id="reset-button" onClick={handleForgotPassword}>
          Forgot Password?
        </Button>
      )}
    </div>
  );
}

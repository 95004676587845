import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';

import axios from 'axios';
import authHeader from '../Authentication/auth-header';
import CouponGenerator from './couponGenerator';
import ViewCoupon from './viewCoupons';

class KlaviyoCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      couponCode: '',
      discountType: 'multiple',
      times: 1,
      percentage: '0',
      couponInputType: 'userDefined',
      numOfCoupons: 1,
      start_date: '',
      expiry_date: '',
      viewCouponPage: false,
      createdCouponObj: {},
      maxDollarAmount: 0.0,
      minThresholdVolume: 0.0,
      maxThresholdVolume:0.0,
      selectedConditions:[],
      machineIds:[],
      selectedMachineIds: [],
      selectAll:false,
      searchQuery: '',
      filteredList:[]
    };
  }
  
  async componentDidMount(){
    axios({
      method: 'get',
      url: `/api/user/get_users_machine_ids`,
      headers: authHeader()
  }).then(response=>{
    this.setState({machineIds: response["data"]["machineIds"], filteredList: response["data"]["machineIds"]})
  })
}

  addCoupon = (event) => {
    event.preventDefault();
    axios({
            method: 'post',
            url: '/api/coupon/createKlaviyoCoupons',
            headers: authHeader(),
            data: this.state

        }).then((response)=>{
            if(response.status == 201){
              console.log(response.data)
                alert("Coupons created")
            }
            else{
                alert("Some error occured. Please try again")
            }
            this.setState({
                couponCode: '',
                discountType: 'multiple',
                times: 1,
                percentage: '0',
                couponInputType: 'userDefined',
                numOfCoupons: 1,
                start_date: '',
                expiry_date: '',
                viewCouponPage: true,
                createdCouponObj : response.data,
                maxDollarAmount: 0.0,
                minThresholdVolume: 0.0,
                maxThresholdVolume:0.0,
                selectedConditions:[],
                machineIds:[],
                selectedMachineIds: [],
                selectAll:false,
                searchQuery: '',
                filteredList:[],
                isOpen: false
            })
        })
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  updateGeneratedCoupons = (coupons, numOfCoupons) => {
    this.setState({ generatedCoupons: coupons, numOfCoupons: numOfCoupons });
  }
  
  handleOptionChange = (option) => {
    const { selectedConditions } = this.state;
    if (selectedConditions.includes(option)) {
      this.setState({
        selectedConditions: selectedConditions.filter((selectedOption) => selectedOption !== option),
      });
    } else {
      this.setState({
        selectedConditions: [...selectedConditions, option],
      });
    }
  }

handleSelectedStationsChange = (option) => {
  const { selectedMachineIds } = this.state;
  if (selectedMachineIds.includes(option)) {
    this.setState({
      selectedMachineIds: selectedMachineIds.filter((selectedOption) => selectedOption !== option),
    });
  } else {
    this.setState({
      selectedMachineIds: [...selectedMachineIds, option],
    });
  }
}

handleSearch = (event) => {
  const searchQuery = event.target.value;
  this.setState({ searchQuery }, () => {
    this.filterList();
  });
};

filterList = () => {
  const { searchQuery } = this.state;
  const filteredList = this.state.machineIds.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );
  this.setState({ filteredList });
};

handleSelectAllChange = () => {
  const { filteredList, selectAll } = this.state;
  this.setState({
    selectedMachineIds: selectAll ? [] : filteredList,
    selectAll: !selectAll,
  });
}

toggleMenu = () =>{
  let isOpen = !this.state.isOpen
  this.setState({isOpen: isOpen})
}


  render() {

    const couponInputOptions = [
        <option key="userDefined" value="userDefined">Create on your own</option>,
        <option key="autoGenerated" value="autoGenerated">Create Automatically</option>,
      ];
    
      const { filteredList, selectedMachineIds, selectAll } = this.state;

    return (
        <>{this.state.viewCouponPage ?
            <ViewCoupon coupons={this.state.createdCouponObj}/>:
        <div className="box">
          <div className='addCoupon'>
            <Form onSubmit={this.addCoupon}>
            <Form.Group controlId="formBasicSelect">
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control 
                  as="select"
                  name="couponInputType"
                  onChange={this.handleChange}
                >
                  {couponInputOptions}
                </Form.Control>
              </Form.Group>
              {this.state.couponInputType == "userDefined" ?
              <Form.Group controlId="formBasicSelect">
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control 
                  name="couponCode"
                  type="text"
                  value={this.state.couponCode}
                  onChange={this.handleChange}
                  placeholder="Coupon Code"
                />
              </Form.Group>:
              <CouponGenerator onGenerate={this.updateGeneratedCoupons}
              />
              }
              <Form.Group controlId="numberInput">
                <Form.Label>Number of Samples</Form.Label>
                <Form.Control 
                  name="times"
                  type="number"
                  value={this.state.times}
                  onChange={this.handleChange}
                  placeholder="Number of Times"
                />
              </Form.Group>
              <div className="collapsible-menu">
              <button type="button" onClick={this.toggleMenu}>Assign Stations</button> <br/>
              {this.state.isOpen &&
              <>
              <label>Stations</label>
                <input
                    type="text"
                    placeholder="Search..."
                    value={this.state.searchQuery}
                    onChange={this.handleSearch}
                  />
                <div className='checkbox-container'> 
                        <input
                            type="checkbox"
                            id="selectAll"
                            checked={selectAll}
                            onChange={this.handleSelectAllChange}
                            />
                            <label className='checkbox-label' htmlFor="selectAll">Select All</label>
                        </div>
                <div className='container'>
                    {filteredList.map((option, index) => (
                    <div key={option} className='checkbox-container'>
                        <input
                        type="checkbox"
                        id={`option${index}`}
                        value={option}
                        checked={this.state.selectedMachineIds.includes(option)}
                        onChange={() => this.handleSelectedStationsChange(option)}
                        />
                        <label className='checkbox-label' htmlFor={`option${index}`}>{option}</label>
                    
                    </div>
                    ))}
                    </div>
                    </>
  }
                </div>
              <br/>
              {((this.state.couponInputType=="userDefined" && this.state.couponCode!='') ||
                (this.state.couponInputType=="autoGenerated" && this.state.generatedCoupons !=[]) || this.state.numOfCoupons>100) ?
              <Button variant="primary" type="submit" className='button'>
                Create Coupons
              </Button>
              :
              <Button variant="primary" type="submit" className='button' disabled>
                Add Coupons to Stations
              </Button>}
            </Form>
          </div>
      </div>}
      </>
    )
  }
}

export default KlaviyoCoupon;

import React from 'react';

import './Dispense.css'


class Dispense extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            totalDispense: {
                'dispensedToday': {
                    'quantity': 0,
                    'size_unit': 'ml'
                },
                'dispensedWeek': {
                    'quantity': 0,
                    'size_unit': 'ml'
                },
                'dispensedMonth': {
                    'quantity': 0,
                    'size_unit': 'ml'
                },
                'dispensedTotal': {
                    'quantity': 0,
                    'size_unit': 'ml'
                }
            }
        }
    }

    componentDidMount(){
     
        let totalDispense = 
            {
                'dispensedToday':
                    {
                    'quantity': 0,
                    'price': 0
                    },
                'dispensedWeek':
                    {
                    'quantity': 0,
                    'price': 0
                    },
                'dispensedMonth':
                    {
                    'quantity': 0,
                    'price': 0
                    },
                'dispensedTotal':
                    {
                    'quantity': 0,
                    'price': 0
                    },
                'sizeUnit': ''
            }
        let dateToday = new Date();
        dateToday.setDate(dateToday.getDate() - 1);
        dateToday.setTime(dateToday.getTime() - (6 * 60 * 60 * 1000))
        let dayOfWeek = dateToday.getDay()!=0?dateToday.getDay():7
        let dayOfMonth = dateToday.getDate()
        // dateToday.setHours(0,0,0,0)
        let dateWeek = new Date();
        dateWeek.setHours(0,0,0,0)
        dateWeek.setDate(dateWeek.getDate() - 7);
        

        let dateMonth = new Date()
        dateMonth.setHours(0,0,0,0)
        dateMonth.setDate(dateMonth.getDate()-30)
        
        let data = this.props.orders
        for(let index in data){  
            if(Date.parse(data[index]['start_time']) > dateMonth){
                if(data[index]['quantity_dispensed'] > 0){
                    totalDispense['dispensedMonth']['quantity']+= parseFloat(data[index]['quantity_dispensed'])
                    totalDispense['dispensedMonth']['price'] += parseFloat(data[index]['price'])
                }
            }
            if(Date.parse(data[index]['start_time']) > dateWeek){
                if(data[index]['quantity_dispensed'] > 0){
                    totalDispense['dispensedWeek']['quantity']+= parseFloat(data[index]['quantity_dispensed'])
                    totalDispense['dispensedWeek']['price'] += parseFloat(data[index]['price'])
                }
            }
            if(Date.parse(data[index]['start_time']) > dateToday.getTime()){
                if(data[index]['quantity_dispensed'] > 0){
                    totalDispense['dispensedToday']['quantity']+= parseFloat(data[index]['quantity_dispensed'])
                    totalDispense['dispensedToday']['price'] += parseFloat(data[index]['price'])
                }
            }
            // if(data[index]['quantity_dispensed'] > 0){
            //     totalDispense['dispensedTotal']['quantity']+= parseFloat(data[index]['quantity_dispensed'])
            //     totalDispense['dispensedTotal']['price'] += parseFloat(data[index]['price'])
            // }
            
            totalDispense['sizeUnit'] = data[index]['size_unit']
        }
        totalDispense['dispensedWeek']['quantity'] = (totalDispense['dispensedWeek']['quantity']/7)
        let launchDate = new Date(this.props.launchDate)
        let currentDate = new Date()
        const timeDifference = currentDate - launchDate ;

            // Calculate the number of days
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;

        if(daysDifference > 30){
            totalDispense['dispensedMonth']['quantity'] = totalDispense['dispensedMonth']['quantity']/30
        }
        else{
            totalDispense['dispensedMonth']['quantity'] = totalDispense['dispensedMonth']['quantity']/daysDifference
        }
        
        this.setState({totalDispense: totalDispense})
    }

   
    render(){
        let totalDispense = this.state.totalDispense
        return (
         <>
           <div className='dispenseContainer '>
                <div className='dispensed table-4 p1 table-bg clearfit'>
                    <div className='timeFrame'>
                        24 hours
                    </div>
                    
                    <div className='quantity'>
                        {(Math.round(totalDispense['dispensedToday']['quantity'])>1000 && totalDispense['sizeUnit']==='ml') ? 
                        <>{((parseFloat(totalDispense['dispensedToday']['quantity']/1000).toFixed(2)))}L</> : 
                            <>{totalDispense['sizeUnit']==='ml' ? Math.round((totalDispense['dispensedToday']['quantity'])): Math.round((totalDispense['dispensedToday']['quantity'])/29.5735)} {totalDispense['sizeUnit']}</>} 
                    </div>
                    <div className='price'>
                        ${Math.round(totalDispense['dispensedToday']['price'])}
                    </div>
                </div>

                <div className='dispensed table-3 p1 clearfit'>
                    <div className='timeFrame'>
                        7 days
                    </div>
                    <div className='quantity'>
                        {(Math.round(totalDispense['dispensedWeek']['quantity'])>1000 && totalDispense['sizeUnit']==='ml') ? 
                        <>{((parseFloat(totalDispense['dispensedWeek']['quantity']/1000).toFixed(2)))}L</> : 
                            <>{totalDispense['sizeUnit']==='ml' ? Math.round((totalDispense['dispensedWeek']['quantity'])): Math.round((totalDispense['dispensedWeek']['quantity'])/29.5735)} {totalDispense['sizeUnit']}</> } (avg/day)
                    </div>
                    <div className='price'>
                        ${Math.round(totalDispense['dispensedWeek']['price'])}
                    </div>
                </div>

                <div className='dispensed table-3 p1 table-bg clearfit'>
                    <div className='timeFrame'>
                        30 days
                    </div>
                    <div className='quantity'>
                        {(Math.round(totalDispense['dispensedMonth']['quantity'])>1000 && totalDispense['sizeUnit']==='ml') ? 
                        <>{((parseFloat(totalDispense['dispensedMonth']['quantity']/1000).toFixed(2)))}L</> : 
                            <>{totalDispense['sizeUnit']==='ml' ? Math.round((totalDispense['dispensedMonth']['quantity'])): Math.round((totalDispense['dispensedMonth']['quantity'])/29.5735)} {totalDispense['sizeUnit']}</>}  (avg/day)
                    </div>
                    <div className='price'>
                        ${Math.round(totalDispense['dispensedMonth']['price'])}
                    </div>
                </div>
                <div className='dispensed table-3 p1  clearfit'>
                    {/* <div className='timeFrame'>
                        All&nbsp;Time 
                    </div>
                    <div className='quantity'>
                        {(Math.round(totalDispense['dispensedTotal']['quantity'])>1000 && totalDispense['sizeUnit']==='ml') ? 
                        <>{((parseFloat(totalDispense['dispensedTotal']['quantity']/1000).toFixed(2)))}L</> : 
                            <>{totalDispense['sizeUnit']==='ml' ? Math.round((totalDispense['dispensedTotal']['quantity'])): Math.round((totalDispense['dispensedTotal']['quantity'])/29.5735)} {totalDispense['sizeUnit']}</>}  (total dispensed)
                    </div>
                    <div className='price'>
                        ${Math.round(totalDispense['dispensedTotal']['price'])}
                    </div> */}
                </div>
           </div>
         </>
        )

    }
    
}

export default Dispense;
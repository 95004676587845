import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';

class CouponGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numCharacters: 5,
            regexPattern: '',
            numCoupons: 1,
            generatedCoupons: []
        };
    }

    handleGenerate = (event) => {
        event.preventDefault();
        const coupons = this.generateCoupons(this.state.numCharacters, this.state.regexPattern, this.state.numCoupons); // assuming we generate 5 coupons
        this.setState({ generatedCoupons: coupons });
        this.props.onGenerate(coupons, this.state.numCoupons);
    }

    handleChangeGenerator = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    generateCoupons(numCharacters, regexPattern, numCoupons) {
        let coupons = [];
        
        for (let i = 0; i < numCoupons; i++) {
            let coupon = '';
            
            // If regexPattern is empty or null, use a basic condition to ensure the coupon isn't empty
            // Otherwise, use the regex to test the coupon
            while (
                (regexPattern ? !new RegExp(regexPattern).test(coupon) : !coupon) || 
                coupons.includes(coupon)
            ) {
                coupon = this.generateRandomString(numCharacters);
            }
            coupons.push(coupon);
        }
        return coupons;
    }

    generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    render() {
        return (
            <>
                <Form.Group controlId="numberInput">
                    <Form.Label>Number of characters</Form.Label>
                    <Form.Control 
                        type="number"
                        name="numCharacters"
                        value={this.state.numCharacters} 
                        onChange={this.handleChangeGenerator}
                    />
                </Form.Group>

                <Form.Group controlId="regexInput">
                    <Form.Label>Pattern (e.g., ^[A-Z])</Form.Label>
                    <Form.Control 
                        type="text"
                        name="regexPattern"
                        value={this.state.regexPattern}
                        onChange={this.handleChangeGenerator}
                    />
                </Form.Group>
                <Form.Group controlId="numberInput">
                    <Form.Label>Number of coupons</Form.Label>
                    <Form.Control 
                        type="number"
                        name="numCoupons"
                        value={this.state.numCoupons} 
                        onChange={this.handleChangeGenerator}
                    />
                </Form.Group>
                
                <Button variant="primary" type="submit" onClick={this.handleGenerate}>
                    Generate Coupons
                </Button>

                <div><b>Generated Coupons:</b> {this.state.generatedCoupons.filter(Boolean).join(', ')}</div>
                </>
        );
    }
}

export default CouponGenerator;

import React from "react";
import { CSVLink } from "react-csv";
import axios from "axios";
import authHeader from "../Authentication/auth-header";
import "./Order.css";
import ChartAelen from "../Charts/ChartAelen";

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      currentSort: "down",
      fromDate: "",
      toDate: "",
      orders: [],
      showAveragesChart: false,
      selectedTimezone: "PST",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const currentDate = new Date().toISOString().split("T")[0];
    this.setState({
      orders: this.props.orders,
      toDate: currentDate,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedTimezone !== this.state.selectedTimezone) {
      this.forceUpdate(); // Forces re-render to reflect timezone changes
    }
  }

  getRecords = (e) => {
    e.preventDefault();

    let toDate = new Date(this.state.toDate);
    toDate.setDate(toDate.getDate() + 1);

    let adjustedToDate = toDate.toISOString().split("T")[0];

    let payload = {
      fromDate: this.state.fromDate,
      toDate: adjustedToDate,
      MachineSerialNumber: this.props.serialNumber,
    };
    axios({
      method: "post",
      url: "/api/user/get_custom_date_orders_station",
      headers: authHeader(),
      data: payload,
    }).then((response) => {
      this.setState({ orders: response.data });
    });
  };

  toggleSort = () => {
    const { currentSort } = this.state;
    if (currentSort === "down") {
      this.setState({ currentSort: "up" });
    } else if (currentSort === "up") {
      this.setState({ currentSort: "serial" });
    } else {
      this.setState({ currentSort: "down" });
    }
  };

  toggleSortBySerial = () => {
    const { currentSort } = this.state;
    if (currentSort === "serial") {
      this.setState({ currentSort: "serial-up" });
    } else {
      this.setState({ currentSort: "serial" });
    }
  };

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  handleTimezoneChange = (e) => {
    const selectedTimezone = e.target.value;
    this.setState({ selectedTimezone });
  };

  convertToTimezone(pstTimeString, targetTimezone) {
    const pstOffset = -8; // PST offset is UTC-8
    const targetOffset = { // Define offsets for different timezones
        "EST": -5,
        "CST": -6,
        "PST": -8
    };
    const dateObj = new Date(pstTimeString);
    pstTimeString = dateObj.toISOString().replace("Z", "");
    
    const needsConversion = targetTimezone !== "PST"; // No conversion if both are PST
    if (needsConversion) {
        // Get the target timezone offset
        const offsetDiff = targetOffset[targetTimezone] - pstOffset;
        if (typeof targetOffset[targetTimezone] === 'undefined') {
            console.log("Invalid target timezone:", targetTimezone);
        }

        const offsetMilliseconds = offsetDiff * 60 * 60 * 1000; 
        const pstDate = new Date(pstTimeString);
        const targetTime = new Date(pstDate.getTime() + offsetMilliseconds);

        return targetTime.toLocaleString("en-US");
    } else {
        const pstDate = new Date(pstTimeString);
        return pstDate.toLocaleString("en-US");
    }
}

  render() {
    const timezoneOptions = [
      { value: "PST", label: "Pacific (PST)" },
      { value: "EST", label: "Eastern (EST)" },
      { value: "CST", label: "Central (CST)" },
    ];

    const sortTypes = {
      up: {
        class: "sort-up",
        fn: (a, b) => new Date(a.start_time) - new Date(b.start_time),
      },
      down: {
        class: "sort-down",
        fn: (a, b) => new Date(b.start_time) - new Date(a.start_time),
      },
      serial: {
        class: "sort",
        fn: (a, b) =>
          a.soapstand_product_serial.localeCompare(b.soapstand_product_serial),
      },
      "serial-up": {
        class: "sort",
        fn: (a, b) =>
          b.soapstand_product_serial.localeCompare(a.soapstand_product_serial),
      },
    };
    const { currentSort } = this.state;
    let exportOrder = [];
    let orders = this.state.orders;

    for (var i in orders) {
      let order = orders[i];
      let quantity_requested = parseFloat(
        order["quantity_requested"]
      ).toFixed();
      let quantity_dispensed = parseFloat(
        order["quantity_dispensed"]
      ).toFixed();
      let soapstand_product_serial = order["soapstand_product_serial"]
      let startTime = order["start_time"];
      let end_time = order["end_time"];
      let start = new Date(startTime);
      let end = new Date(end_time);
      let dispenseTime = (end - start) / 1000;
      const [date,time] = this.convertToTimezone(
        startTime,
        this.state.selectedTimezone
      ).split(", ");

      let buttonDuration = order["buttonDuration"];
      let dispenseRate = (
        (orders[0].size_unit === "oz"
          ? quantity_dispensed / 29.5735
          : quantity_dispensed) /
        (buttonDuration / 1000)
      ).toFixed(2);
      let size_unit = orders[0].size_unit;
      let rate = `${dispenseRate} ${size_unit}/s`;
      let price = parseFloat(order["price"]).toFixed(2);

      if (orders[0].size_unit === "oz") {
        quantity_dispensed = (quantity_dispensed / 29.5735).toFixed();
        quantity_requested = (quantity_requested / 29.5735).toFixed();
      }

      let orderStatus = order["orderStatus"];

      if (orderStatus.at(-1) !== "Completed") {
        quantity_dispensed = 0;
        dispenseTime = "";
        rate = "";
        price = "";
      } else {
        orderStatus = "Payment initiated, completed, order dispensed";
      }

      if (orderStatus.at(-1) === "InProgress") {
        orderStatus = "Payment incomplete, no dispense";
      }
      if (orderStatus.at(-1) === "Initiated") {
        orderStatus = "Payment initiated..";
      }

      var individualOrder = {
        location: this.props.location,
        date: date,
        time: time,
        contents: order["contents"],
        soapstand_product_serial,
        quantity_requested,
        quantity_dispensed,
        price,
        orderStatus,
        productId: order["productId"],
        dispenseTime: dispenseTime,
        dispenseRate: rate,
      };
      let slot = order["slot"];
      exportOrder.push(individualOrder);
    }

    let headers = [
      { label: "Location", key: "location" },
      { label: "Date", key: "date" },
      { label: "Time", key: "time" },
      { label: "Product", key: "contents" },
      {label: "Product Id", key:"soapstand_product_serial"},
      { label: "Quantity Requested", key: "quantity_requested" },
      { label: `Quantity Dispensed`, key: "quantity_dispensed" },
      { label: `Total Dispense Time (s)`, key: "dispenseTime" },
      { label: "Dispense Rate", key: "dispenseRate" },
      { label: "Amount Paid", key: "price" },
      { label: "Status", key: "orderStatus" },
      // { label: "Product Id", key: "productId" },
    ];

    return (
      <>
        <div ref={(ref) => (this.myRef = ref)}></div>

        {this.props.orders.length !== 0 ? (
          <>
            {this.state.orders.length > 0 && (
              <ChartAelen
                orders={this.state.orders}
                currentSort={this.state.currentSort}
              />
            )}
            <form
              onSubmit={this.getRecords}
              className="transaction-reports"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="date-range-input">
                <label htmlFor="fromDate" className="allOrders-dateLabel">
                  From Date:
                </label>
                <input
                  type="date"
                  id="fromDate"
                  value={this.state.fromDate}
                  onChange={(e) => this.setState({ fromDate: e.target.value })}
                  className="dateInput"
                  required
                />
              </div>
              <div className="date-range-input">
                <label htmlFor="toDate" className="allOrders-dateLabel">
                  To Date:
                </label>
                <input
                  type="date"
                  id="toDate"
                  value={this.state.toDate}
                  onChange={(e) => this.setState({ toDate: e.target.value })}
                  className="dateInput"
                  required
                />
              </div>
              <button type="submit" className="button-getReport">
                Custom records
              </button>
            </form>
            <div className="infoRowOrder">
              <div className="ownerInfo">
                {this.props.orders[0]["MachineSerialNumber"]} Transaction Logs
              </div>
              <div className="btn-groups">
                <div className="timezone-dropdown">
                  <label htmlFor="timezone" className="allOrders-dateLabel">
                      Timezone:
                    </label>{" "}
                    <select
                        id="timezone"
                        value={this.state.selectedTimezone}
                        onChange={this.handleTimezoneChange}
                        className="timezone-dropdown"
                      >
                        {timezoneOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                </div>
                <button onClick={this.toggleSort} className="btn btn-main">
                  Sort By Date{" "}
                  {currentSort === "down" ? (
                    <i className="fa-solid fa-sort-down"></i>
                  ) : currentSort === "up" ? (
                    <i className="fa-solid fa-sort-up"></i>
                  ) : (
                    <i className="fa-solid fa-sort"></i>
                  )}
                </button>
                <button onClick={this.toggleSortBySerial} className="btn btn-main">
                    Sort By Product Id{" "}
                    {currentSort === "serial" ? (
                      <i className="fa-solid fa-sort-down"></i>
                    ) : currentSort === "serial-up" ? (
                      <i className="fa-solid fa-sort-up"></i>
                    ) : (
                      <i className="fa-solid fa-sort"></i>
                    )}
                  </button>

                <CSVLink
                  data={exportOrder}
                  headers={headers}
                  filename="orders-data"
                  className="btn btn-main"
                >
                  Export Data
                </CSVLink>
              </div>
            </div>

            <div className="transactionTable">
              <table id="orders">
                <tbody>
                  <tr key={"Heading"} class="heading">
                  <td className="header_location">Location</td>
                    <td>Date</td>
                    <td>Time ({this.state.selectedTimezone})</td>
                    <td> Product Id</td>
                    <td>Product Name</td>
                    <td className="header_quantityRequested">
                      Quantity Requested{" "}
                      <span className="header_quantitySize">
                        ({this.props.orders[0].size_unit})
                      </span>
                    </td>
                    <td className="header_quantityDispensed">
                      Quantity Dispensed{" "}
                      <span className="header_quantitySize">
                        ({this.props.orders[0].size_unit})
                      </span>
                    </td>
                    <td className="header_quantityDispensed">
                      Quantity remaining{" "}
                      <span className="header_quantitySize">
                        ({this.props.orders[0].size_unit})
                      </span>
                    </td>
                    <td className="header_dispenseTime">
                      Total Dispense Time
                      <br />
                      <span className="header_quantitySize"> (seconds)</span>
                    </td>
                   
                    <td className="header_dispenseRate">
                      Dispense Rate
                      <br />
                      <span className="header_quantitySize">
                        ({this.props.orders[0].size_unit}/s)
                      </span>
                    </td>
                    <td>
                      Paid<span className="header_quantitySize"> ($)</span>
                    </td>
                    <td className="header_status">Status</td>
                  </tr>

                  {this.state.orders
                    .sort(sortTypes[currentSort].fn)
                    .map((order, index) => {
                      let {
                        orderId,
                        start_time,
                        end_time,
                        soapstand_product_serial,
                        contents,
                        quantity_requested,
                        quantity_dispensed,
                        volume_remaining_ml,
                        price,
                        orderStatus,
                        buttonDuration,
                      } = order;
                      if (contents) {
                        contents = contents.replace("<br>", " ");
                      }

                      const [date,time] =
                        this.convertToTimezone(start_time,  this.state.selectedTimezone).split(", ");

                      quantity_requested = parseFloat(quantity_requested);
                      quantity_dispensed =
                        parseFloat(quantity_dispensed).toFixed(2);
                      quantity_dispensed = Math.round(
                        quantity_dispensed,
                        2
                      ).toString();

                      const startTime = new Date(start_time);
                      const endTime = new Date(end_time);
                      const dispenseTime = (endTime - startTime) / 1000;
                      price = parseFloat(price).toFixed(2);
                      volume_remaining_ml =parseFloat(volume_remaining_ml).toFixed(2);

                      if (quantity_dispensed != NaN) {
                        if (this.props.orders[0].size_unit === "oz") {
                          quantity_dispensed = (
                            quantity_dispensed / 29.5735
                          ).toFixed();
                          quantity_requested = (
                            quantity_requested / 29.5735
                          ).toFixed(2);
                            volume_remaining_ml = (parseFloat(volume_remaining_ml) / 29.5735).toFixed(2);
                        }
                      }
                      price = parseFloat(price).toFixed(2);
                      const dispenseRate = (
                        quantity_dispensed /
                        (buttonDuration / 1000)
                      ).toFixed(2);
                      orderStatus = orderStatus.at(-1);
                      if (orderStatus == "Initiated") {
                        orderStatus = "QR displayed";
                      } else if (
                        orderStatus == "In Progress" ||
                        orderStatus == "InProgress"
                      ) {
                        orderStatus = "QR scanned; Not paid";
                      }

                      return (
                        <tr key={orderId} class="tableOrdersRow">
                          <td>{this.props.location}</td>
                          <td>{date}</td>
                          <td>{time}</td>
                          <td>{soapstand_product_serial}</td>
                          <td>{contents}</td>
                          <td>{Math.round(quantity_requested, 2)}</td>
                          {!isNaN(quantity_dispensed) ? (
                            <>
                              <td>{quantity_dispensed}</td>
                              <td>{isNaN(volume_remaining_ml) ? "N/A" : volume_remaining_ml}</td>
                              <td>{dispenseTime}</td>
                              <td>
                                {isFinite(dispenseRate) ? dispenseRate : ""}
                              </td>
                              <td>{price}</td>

                            </>
                          ) : (
                            <>
                              <td>0</td>
                              <td>{volume_remaining_ml}</td>
                              <td>-</td>
                              <td>-</td>
                              <td>N/A</td>
                            </>
                          )}
                          <td>{orderStatus}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <button onClick={this.scrollToTop} id="scroll-to-top">
              <i className="fa-solid fa-arrow-up"></i>
            </button>
          </>
        ) : (
          <>
            <h3>No transactions available</h3>
          </>
        )}
      </>
    );
  }
}

export default Order;

import React from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dropdown/style.css';
import './coupons.css'
import authHeader from '../Authentication/auth-header';
import CouponOrder from './couponOrders';


class Coupon extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            coupons: [],
            showUsage: false,
            ordersForCoupon: []
        }
    }

    async componentDidMount(){
        this.get_all_coupons();
    }

    get_all_coupons = () =>{
        axios({
            method: 'get',
            url: '/api/coupon/find_all',
            headers: authHeader()
        }).then((response)=>{
            this.setState({coupons: response.data})
        })
    }

    showCouponUsage = (Orders)=>{
        this.setState({showUsage: true, ordersForCoupon: Orders})
    }

    hideCouponUsage = ()=>{
        this.setState({showUsage: false, ordersForCoupon: []})
    }
    
    render(){
        return (
            <>
            {this.state.showUsage?
            <CouponOrder orders={this.state.ordersForCoupon} hideFunction={this.hideCouponUsage}/>:
             <div className='couponsTable'>
            <table id='coupons'>
               <tbody>
                 <tr key={'Heading'} class='heading'>
                     <td>Coupon Code</td>
                     <td>Active</td>
                     <td>Discount</td>
                     <td>Coupon Type</td>
                     <td>Start Date</td>
                     <td>Expiry Date</td>
                     <td>Usage</td>
                 </tr>
                 {this.state.coupons.map((coupon) => {
                    let { couponCode, active, coupon_type, discount_amount, start_date, expiry_date,Orders } = coupon
                     const date_time= expiry_date.split("T")
                     const date = date_time[0]
                     if (coupon_type == null){
                         coupon_type = ""
                     }
                     else if(coupon_type.includes("MULTI")){
                         let attemptsLeft = coupon_type.split("_")
                         coupon_type = attemptsLeft[0] + " (" + attemptsLeft[1] + " )"
                     }
                     else if(coupon_type == "ONE_TIME"){
                         coupon_type = "SINGLE USE"
                     }
                    
                     
                     return (
                        <tr key={couponCode}>
                           <td>{couponCode}</td>
                           <td>{active? "Active": "Inactive"}</td>
                           <td>{discount_amount}</td>
                           <td>{coupon_type}</td>
                           <td>{start_date.split("T")[0]}</td>
                           <td>{date}</td>  
                           {Orders.length !=0 ?
                                <td><button className="btn btn-main" onClick={()=>this.showCouponUsage(Orders)}>View Usage</button></td>                   
                           :
                           <td>No Usage</td>                   
                        }
                        </tr>
                        )
                    })}   
               </tbody>
            </table>
            

            </div>
            }</>
        )

    }
    
}

export default Coupon;
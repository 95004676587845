import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import authHeader from '../Authentication/auth-header';
import './Station.css'
import Button from 'react-bootstrap/esm/Button';

class AddStation extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }

    add_station = (event) =>{
        event.preventDefault();
        const elements = event.target.elements
        var formData = {}
        formData['serialNumber']= elements.serialNumber.value
        formData['UserEmail']=elements.userEmail.value
        formData['location']= elements.location.value
        // console.log(formData)
        
        axios({
            method: 'post',
            url: '/api/machine/add_machine',
            headers: authHeader(),
            data: formData

        }).then((response)=>{
            console.log(response.data)
        })
    }
   
 

    render(){

        return (
            <div className='addStation'>
                <Form onSubmit={this.add_station}>
                    <Form.Group>
                        <Form.Label>Serial Number</Form.Label>
                        <Form.Control type="text" placeholder="Serial Number" name="serialNumber"/>
                    </Form.Group>   
                    <Form.Group>       
                        <Form.Label>User Email</Form.Label>
                        <Form.Control type="email" placeholder="abc@soapstand.com" name="userEmail"/>
                    </Form.Group>       
                    <Form.Group>       
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" placeholder="city, province, Country" name="location"/>
                    </Form.Group> 
                    <Button variant="primary" type="submit">
                        Add Station
                    </Button>
                       
                </Form>
            </div>
        )

    }
    
}

export default AddStation;
import React from "react";
import axios from "axios";
import authHeader from "../Authentication/auth-header";
import AuthService from "../Authentication/AuthService";
// import ReactPaginate from "react-paginate";
// import "./Pagination.css";
import "./Station.css";
import StationInfo from "./StationInfo";
import Order from "../Order/Order";
import AlertBanner from "../Alerts/AlertBanner";
import TempStatus from "../Alerts/TempStatus";

class Station extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      machines: {},
      selectedMachines: {},
      allMachines: {},
      userMachines: {},
      orders: [],
      user: {},
      showStations: true,
      currentPage: 1,
      totalPages: 1,
      roles: {},
      showSpinner: true,
      machineIds: [],
      selectedOptions: [],
      selectAll: false,
      tempIssues: [],
      filter: 'serialNumber',
      lastUpdated: new Date()
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.myRef = React.createRef();
  }

  sort_stations = (stations) => {
    return stations.sort((a, b) => (a.serialNumber > b.serialNumber ? 1 : -1));
  };

  view_transactions = (orders, location, launchDate, serialNumber) => {
    this.setState({
      showStations: false,
      orders: orders,
      location: location,
      launchDate: launchDate,
      serialNumber: serialNumber,
    });
  };
  hide_transactions = () => {
    this.setState({
      showStations: true,
    });
  };

  handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    this.setState({ machines: {}, showSpinner: true });
    this.get_user_machines(currentPage);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  getUserPermissionsBasedOnRole = () => {
    axios({
      method: "get",
      url: `/api/role/find_all`,
      headers: authHeader(),
    }).then((data) => {
      this.setState({ roles: data });
    });
  };

  async componentDidMount() {
    this.getUserPermissionsBasedOnRole();
    const user = await AuthService.getCurrentUser();
    this.setState({ user: user });
    this.get_user_machines(this.state.currentPage);
  }

  handleOptionChange = (option) => {
    const { selectedOptions, machines } = this.state;
    const updatedSelectedOptions = [...selectedOptions]; // Create a copy of selectedOptions

    if (updatedSelectedOptions.includes(option)) {
      // If the option is already selected, remove it
      updatedSelectedOptions.splice(updatedSelectedOptions.indexOf(option), 1);
    } else {
      // If the option is not selected, add it
      updatedSelectedOptions.push(option);
    }

    // Now, update the selectedOptions state
    this.setState({ selectedOptions: updatedSelectedOptions }, () => {
      // After updating selectedOptions, filter the machines based on it
      let filteredMachines;

      if (updatedSelectedOptions.length === 0) {
        // If none are selected, show all machines
        filteredMachines = machines.undefined;
      } else {
        // If some checkboxes are selected, filter machines based on updatedSelectedOptions
        filteredMachines = machines.undefined.filter((machine) =>
          updatedSelectedOptions.includes(machine.serialNumber)
        );
      }

      // Now set the state with the filtered machines
      this.setState({
        selectedMachines: { undefined: filteredMachines },
      });
    });
  };

  selectStations = (event) => {
    this.setState({ showStations: true });
  };

  handleSelectAllChange = () => {
    const { machineIds, selectAll, machines } = this.state;
    this.setState({
      selectedOptions: selectAll ? [] : machineIds,
      selectAll: !selectAll,
      allMachines: machines,
      selectedMachines: machines,
    });
  };

  get_user_machines = (currentPage) => {
    axios({
      method: "get",
      url: `/api/user/get_my_machines/${currentPage}`,
      headers: authHeader(),
    }).then((response) => {
      let machines = response.data["machines"];
      let pageInfo = response.data["pageInfo"];
      let userMachines = response.data["userMachines"];
      let machineIds = response.data["machineIds"];
      machines = this.sort_stations(machines);
      let result = machines.reduce(function (r, a) {
        r[a.UserEmail] = r[a.UserEmail] || [];
        r[a.UserEmail].push(a);
        return r;
      }, Object.create(null));
      this.setState({ userMachines: userMachines });
      this.setState({
        totalPages: pageInfo["totalNumberOfPages"],
        currentPage: pageInfo["currentPage"],
        machineIds: machineIds,
      });
      this.setState({
        machines: result,
        allMachines: machines,
        showSpinner: false,
        selectedMachines: result,
      });
    });
  };

  setTempIssues = (issues) => {
    this.setState({ tempIssues: issues });
  };

//   setTempIssues = (newIssue) => {
//     this.setState(prev => ({
//       tempIssues: [...prev.tempIssues, newIssue]
//   }));
// }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  trimSerial(serial) {
    return serial.startsWith("AP")
      ? serial
      : serial.replace(/^SS-0+(\d+)/, "SS-$1");
  }

  trimLocation(location) {
    const [firstPart] = location.split(",", 1);
    return firstPart.trim();
  }
  offlineStations(machines) {
    return Object.values(machines).reduce((count, machineArray) => {
      return (
        count +
        machineArray.reduce(
          (subCount, machine) => subCount + (!machine.active ? 1 : 0),
          0
        )
      );
    }, 0);
  }
  unavailableProducts(products) {
    return products.reduce(
      (count, product) => count + (product.volume_remaining <= 0 ? 1 : 0),
      0
    );
  }
  unavailableProductsList(products) {
    const unavailableProducts = products.filter(product => product.volume_remaining <= 0);
    return unavailableProducts;
  }
  lowVolumeProducts(products) {
    return products.reduce((count, product) => {
      const percentage = parseInt(
        (100 * product.volume_remaining) / product.volume_full
      );
      return count + (percentage > 0 && percentage < 10 ? 1 : 0);
    }, 0);
  }
  productLowVolume(product){
    const percentage = parseInt(
      (100 * product.volume_remaining) / product.volume_full
    );
    return percentage > 0 && percentage < 10;
  }
  lowVolumeProductsList(products){
    const lowVolumeProducts = products.filter(this.productLowVolume);
    return lowVolumeProducts;
  }

  scroll(serialNumber){
    if(serialNumber){
      const section = document.querySelector(`.target-${serialNumber}`);
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      section.style.boxShadow = '0 0 5px 3px red';
      setTimeout(() => {
        section.style.boxShadow = '';
      }, 3000);
    }
  } 

  displayUnavailableProducts(machine){
    const issueDetails = this.unavailableProductsList(machine.Products);
    // Create a string from issueDetails, listing names (or any other property)
    let titles = "Unavailable Products: \n\n"
    titles += issueDetails.map((product, index) => `${index + 1}: ${product.name}`).join("\n\n");
    return titles;
  }

  displayLowVolumeProducts(machine){
    const issueDetails = this.lowVolumeProductsList(machine.Products);
    let titles = "Low Volume Products: \n\n"
    titles += issueDetails.map((product, index) => `${index + 1}: ${product.name}`).join("\n\n");
    return titles;
  }

  filterMachineIds(){
    const machinesArray = Object.values(this.state.allMachines); 
    let filteredMachinesId = [];
    if(this.state.filter === 'serialNumber'){ // sort by serial number
      filteredMachinesId  = [...this.state.machineIds];
    }else if(this.state.filter === 'port'){ // sort by aws port
      const sortedMachines = machinesArray.sort((a, b) => {
        return Number(a.aws_port) - Number(b.aws_port);
      });
      filteredMachinesId = sortedMachines.map(machine => machine.serialNumber);
    }else if(this.state.filter === 'inactive'){ // sort by stations with wifi issues
      filteredMachinesId = machinesArray.filter((machine)=> !machine.active).map(machine => machine.serialNumber);
    }else if(this.state.filter === 'unavailable'){ // sort by stations with unavailable products
      filteredMachinesId = machinesArray.filter((machine)=> this.unavailableProducts(machine.Products) > 0 ).map(machine => machine.serialNumber);
    }else if(this.state.filter === 'low'){ // sort by stations with low volume products
      filteredMachinesId = machinesArray.filter((machine)=> this.lowVolumeProducts(machine.Products) > 0 ).map(machine => machine.serialNumber);
    }else{ // sort by stations with any problems (station inactive, products unavailable, products low volume)
      filteredMachinesId = machinesArray.filter((machine)=>{
        return !machine.active || this.unavailableProducts(machine.Products) > 0 || this.lowVolumeProducts(machine.Products) > 0
      }).map(machine => machine.serialNumber);
    }
    return filteredMachinesId;
  }

  changefilter = (changeEvent) => {
    this.setState({
      filter: changeEvent.target.value
    });
  };

  // returnUnavailable = (event) => {
  //   // event.stopPropagation();

  //   const issueDetails = this.collectUnavailable(this.props.machines);
  //   // this.setState({ showOverwatch: true, issueDetails });
  //   return issueDetails;
  // };
  // collectUnavailable(machines) {
  //   let issueDetails = [];
  //   Object.values(machines).forEach((machineArray) => {
  //     machineArray.forEach((machine) => {
  //       const unavailableCount = this.unavailableProducts(machine.Products);
  //       if (unavailableCount > 0) {
  //         issueDetails.push({
  //           type: "unavailableProducts",
  //           count: unavailableCount,
  //           serialNumber: machine.serialNumber,
  //         });
  //       }
  //     });
  //   });
  //   return issueDetails;
  // }
  render() {
    const { machineIds, selectedOptions, selectAll, showStations } = this.state;
    setInterval(function() {
      var now = new Date();
      var hours = now.getHours();
    
      // Check if the current time is between 8 AM and 6 PM (18:00)
      if (hours >= 8 && hours < 18) {
        window.location.reload();
      }
    }, 3600000); // auto refresh page every 1 hour
    let totalUnavailable = Object.values(this.state.allMachines).filter((machine)=> this.unavailableProducts(machine.Products) > 0 ).length;
    let totalLowVolume = Object.values(this.state.allMachines).filter((machine)=> this.lowVolumeProducts(machine.Products) > 0 ).length;
    let totalOffline = Object.values(this.state.allMachines).filter((machine)=> !machine.active).length;
    let totalMachinesWithProblems = Object.values(this.state.allMachines).filter((machine)=>{
      return !machine.active || this.unavailableProducts(machine.Products) > 0 || this.lowVolumeProducts(machine.Products) > 0
    }).length

    return (
      <div className="BodyContainer">
        <div className="marginContainer md-py4 lg-py3 transactions">
          {showStations ? (
            <>
              <div className="overwatch-header">
                <div className="header-left">
                  <div className="lastupdated-text">Last updated at: {this.state.lastUpdated.toLocaleString()}</div>
                  <div className="ownerInfo">
                    {/* <div id="ownerName">Hi {this.state.user.name}</div> */}
                    {this.state.showSpinner && (
                      <div className="loading-spinner"></div>
                    )}
                  </div>

                  {/* <div className="alerts">
                    <AlertBanner
                      machines={this.state.selectedMachines}
                      tempIssues={this.state.tempIssues}
                    />
                    <TempStatus
                      machines={this.state.selectedMachines}
                      setTempIssues={this.setTempIssues}
                    />
                  </div> */}
                </div>

                <div className="allMachineIds-checkbox border">
                  <div className="checkbox-container-titleSection">
                    <div className="checkbox-container-title">
                      <div className="container-title">Select the station you would like to view</div>
                      <TempStatus
                        machines={this.state.selectedMachines}
                        setTempIssues={this.setTempIssues}
                      />
                    </div>

                    {/* adjust this section: remove select all, sort by location/serialNumber, and filter station with problems */}
                    <div className="checkbox-container-sort-filter-title-container">
                      <div className="sort-title">Sort Stations by</div>
                      <div className="filter-title">Filter Stations by</div>
                    </div>
                    <div className="checkbox-container-selectAll">
                      {/* <input
                        type="checkbox"
                        id="selectAll"
                        checked={selectAll}
                        onChange={this.changefilter}
                      />
                      <label className="checkbox-label checkbox-selectAll" htmlFor="selectAll">
                        Select All
                      </label> */}
                      <div className="sort-radio-groups">
                        <div className="radio-btn">
                          <label>
                            <input type="radio" value="serialNumber" checked={this.state.filter === 'serialNumber'} onChange={this.changefilter}/>
                            Serial Number
                          </label>
                        </div>
                        <div className="radio-btn">
                          <label>
                            <input type="radio" value="port" checked={this.state.filter === 'port'} onChange={this.changefilter} />
                            AWS Port
                          </label>
                        </div>
                      </div>
                      <div className="filter-radio-groups">
                        <div className="radio-btn">
                          <label>
                            <input type="radio" value="inactive" checked={this.state.filter === 'inactive'} onChange={this.changefilter}/>
                            Offline Stations {totalOffline > 0 && <span className="text-alert">&nbsp;({totalOffline})</span>}
                          </label>
                        </div>
                        <div className="radio-btn">
                          <label>
                            <input type="radio" value="unavailable" checked={this.state.filter === 'unavailable'} onChange={this.changefilter}/>
                            Unavailable Products {totalUnavailable > 0 && <span className="text-alert">&nbsp;({totalUnavailable})</span>}
                          </label>
                        </div>
                        <div className="radio-btn">
                          <label>
                            <input type="radio" value="low" checked={this.state.filter === 'low'} onChange={this.changefilter}/>
                            Low Volume Products {totalLowVolume > 0 && <span className="text-alert">&nbsp;({totalLowVolume})</span>}
                          </label>
                        </div>
                        <div className="radio-btn">
                          <label>
                            <input type="radio" value="all" checked={this.state.filter === 'all'} onChange={this.changefilter}/>
                            Filter Stations with Problems {totalMachinesWithProblems > 0 && <span className="text-alert">&nbsp;({totalMachinesWithProblems})</span>}
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="container">
                    {this.state.showSpinner && (
                      <div className="loading-text">Loading...</div>
                    )}
                    {this.filterMachineIds().map((option, index) => {
                      const machine = Object.values(this.state.allMachines)
                        .flat()
                        .find((m) => m.serialNumber === option);
                        const machineActive = machine && machine.active;
                        const machineProductUnavailable = machine && this.unavailableProducts(machine.Products) > 0;
                        const machineProductLowVolume = machine && this.lowVolumeProducts(machine.Products) > 0;
                      return (
                        <div className="container-station">
                          <input
                            type="checkbox"
                            id={`option${index}`}
                            value={option}
                            // checked={selectedOptions.includes(option)}
                            // onChange={() => this.handleOptionChange(option)}
                            // onChange={`${this.scroll(machineSerialNumber)}`}
                            onChange={() => machine && this.scroll(machine.serialNumber)}
                            className="container-checkbox"
                          />
                          <label
                            htmlFor={`option${index}`}
                            key={option}
                            className="checkbox-container-station"
                          >
                            <div className="checkbox-label-container">
                              <div className="checkbox-station-info">
                                {/* {machine
                                  ? `${this.trimSerial(
                                      machine.serialNumber
                                    )} - ${this.trimLocation(machine.location)}`
                                  : option} */}
                                <span>
                                  {machine && `${this.trimSerial(machine.serialNumber)}`}
                                </span>
                                <span>
                                  {machine && `${this.trimLocation(machine.location)}`}
                                </span>
                                <span>
                                  {machine && machine.aws_port}
                                </span>
                              </div>
                              <div className="checkbox-alertBanner">
                                <span
                                  className={`alert-icon ${machineActive ? "" : "icon-red"}`}
                                  title="Station Offline"
                                >
                                  <div className="no-wifi">
                                    {!machineActive && <i
                                      className={`fa-solid fa-wifi custom-size`}
                                    ></i>}
                                    {!machineActive && <i className="fa-solid fa-slash custom-size icon-red"></i>}
                                  </div>
                                </span>

                                <span
                                  className={`alert-icon`}
                                  onClick={this.handleUnavailableClick}
                                  title={machine && this.displayUnavailableProducts(machine)}
                                >
                                  {machineProductUnavailable && (
                                    <span className="badge">{this.unavailableProducts(machine.Products)}</span> 
                                  )}
                                  {machineProductUnavailable && <i
                                    className={`fa-solid fa-triangle-exclamation custom-size`}
                                  ></i>}
                                </span>
                                
                                <span
                                  className={`alert-icon`}
                                  onClick={this.handleLowVolumeClick}
                                  title={machine && this.displayLowVolumeProducts(machine)}
                                >
                                  {machineProductLowVolume && (
                                    <span className="badge">{this.lowVolumeProducts(machine.Products)}</span>
                                  )}
                                  {machineProductLowVolume && (
                                  <i
                                    class={`fa-solid fa-battery-quarter custom-size`}
                                  ></i>
                                  )}
                                  {/* <img id="low-volume-icon" src={lowVolumeIcon} alt="low volume icon"></img> */}
                                </span>
                              </div>
                            </div>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="container-homepage">
                {Object.entries(this.state.selectedMachines).map(
                  ([user, machines]) => {
                    return (
                      <div key={user} className="column2" 
                      ref={this.myRef}
                      >
                        {machines.map((machine) => {
                          try {
                            return (
                              <StationInfo
                                machine={machine}
                                key={machine.serialNumber}
                                transactionFn={this.view_transactions}
                                user={this.state.user}
                                rolesArray={this.state.roles}
                                role={
                                  this.state.userMachines[machine.serialNumber][
                                    "RoleName"
                                  ]
                                }
                              />
                            );
                          } catch {
                            return <></>;
                          }
                        })}
                      </div>
                    );
                  }
                )}

                <button onClick={this.scrollToTop} id="scroll-to-top">
                  <i class="fa-solid fa-arrow-up"></i>
                </button>
              </div>

              {/* <ReactPaginate
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>             */}
              {/* <Pagination variant="outlined" color="primary" count={this.state.totalPages} onClick={this.handlePageClick}/> */}
            </>
          ) : (
            <>
              <div className="ownerInfo">
                <div id="ownerName">{this.state.user.name}</div>
              </div>
              <div className="clear">
                <button
                  onClick={() => {
                    this.hide_transactions();
                  }}
                >
                  X
                </button>
              </div>
              <Order
                orders={this.state.orders}
                location={this.state.location}
                serialNumber={this.state.serialNumber}
                showDispense={true}
                launchDate={this.state.launchDate}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Station;

import React from 'react';
import AuthService from '../Authentication/AuthService';
import logo from '../../Logos/Soapstand-logo-white-horizontal.png'

import './Header.css'


class Header extends React.Component {
    constructor(props){
        super(props)
        this.inactivityTimer = null;
        this.state = {
            showMenuOptions: false,
            showHamburger: false,
            needRefresh: false,
            user: {},
            lastUpdated: new Date()
        }
        this.menuBtnRef = React.createRef();
    }
    async componentDidMount(){
        
        if(AuthService.getCurrentUser()){
            this.setState({showHamburger: true})
            const user = await AuthService.getCurrentUser();
            this.setState({ user: user });
        }
        this.inactivityTimer = setTimeout(() => {
            this.setState({needRefresh:true})
          }, 61 * 60 * 1000);
          document.addEventListener('click', this.handleClickOutside);

    }
    
    reloadPage = ()=>{
        window.location.reload();
    }

    
    showMenu = ()=>{
        let showMenuOptions =! this.state.showMenuOptions
        this.setState({
            showMenuOptions: showMenuOptions
        })
    }

    handleClickOutside = (event) => {
        const menuBtn = this.menuBtnRef.current;
        if (menuBtn && !menuBtn.contains(event.target)) {
            this.setState({
                showMenuOptions: false
            })        }
    };

    render(){
       
        
        return (
            <>
            <div class='headerContainer'>
                
                <div class='headerMargin header'>
                    <a href="/" class='logo'><img src={logo} alt={logo}></img></a>
                    {this.state.needRefresh &&  <button onClick={this.reloadPage} className='refreshPage'><>Please refresh <br/>Last updated at: {this.state.lastUpdated.toLocaleString()}</></button>}
                    {this.state.showHamburger && 
                    <div class="menu-btn" ref={this.menuBtnRef} onClick= {()=>{this.showMenu()}}>
                        <div class="menu-btn__burger"></div>
                    </div>}
                    {this.state.showMenuOptions && <div className='menuOptions' >
                    <ul>
                        <li><div>Hi {this.state.user.name}</div></li>
                        {/* <li><a href='/stations'>Stations</a></li> */}
                        <li><a href='/orders'>Transactions</a></li>
                        {(AuthService.checkIfUserIsDeveloper()||AuthService.checkIfUserIsAdmin())&& <>
                        {/* <li><a href='/ticket'>Create Ticket</a></li> */}
                        <li><a href='/coupon'>Coupons</a></li>

                        </>}
                        <li><a href='/changePassword'>Change Password</a></li>
                        <li><a href='/logout'>Sign Out</a></li>
                    </ul>
                </div>}
                </div>
            </div>
            </>
        )

    }
    
}

export default Header;
import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';

import axios from 'axios';
import authHeader from '../Authentication/auth-header';
import ViewCoupon from './viewCoupons';

class ArchivedCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
        options: [],
        selectedOptions: [],
        selectAll: false,
    };
  }

  async componentDidMount(){
    this.get_all_archived_coupons();
}

get_all_archived_coupons = () =>{
    axios({
        method: 'get',
        url: '/api/coupon/get_all_archived_coupons',
        headers: authHeader()
    }).then((response)=>{
        this.setState({options: response.data})
    })
}
  handleOptionChange = (option) => {
    const { selectedOptions } = this.state;

    if (selectedOptions.includes(option)) {
      this.setState({
        selectedOptions: selectedOptions.filter((selectedOption) => selectedOption !== option),
      });
    } else {
      this.setState({
        selectedOptions: [...selectedOptions, option],
      });
    }
  }

  handleSelectAllChange = () => {
    const { options, selectAll } = this.state;

    this.setState({
      selectedOptions: selectAll ? [] : options,
      selectAll: !selectAll,
    });
  }

  handleSubmit = () => {
    const { selectedOptions } = this.state;
  }

  archiveSelected=(event)=>{
    event.preventDefault();
     axios({
            method: 'post',
            url: '/api/coupon/unarchive_selected',
            headers: authHeader(),
            data: {coupons: this.state.selectedOptions}

        }).then((response)=>{
            if(response.status == 200){
                alert("Coupons Unarchived")
                window.location.href = 'https://soapstandportal.com/coupon'
            }
        })
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
 

      const { options, selectedOptions, selectAll } = this.state;

    return (
        <>  
        <div className="box">
          <div className='addCoupon'>
                
            <Form >
            <div className='checkbox-container'> 
                        <input
                            type="checkbox"
                            id="selectAll"
                            checked={selectAll}
                            onChange={this.handleSelectAllChange}
                            />
                            <label className='checkbox-label' htmlFor="selectAll">Select All</label>
                        </div>
                <div className='container'>
                    
                    {options.map((option, index) => (
                    <div key={option} className='checkbox-container'>
                        <input
                        type="checkbox"
                        id={`option${index}`}
                        value={option}
                        checked={selectedOptions.includes(option)}
                        onChange={() => this.handleOptionChange(option)}
                        />
                        <label className='checkbox-label' htmlFor={`option${index}`}>{option}</label>
                    </div>
                    ))}
                </div>
              <Button variant="primary" type="submit" className='button' onClick={this.archiveSelected}>
                Unarchive Coupons
              </Button>
            </Form>
          </div>
        </div>
        </>
    )
  }
}

export default ArchivedCoupon;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dropdown/style.css';
import './coupons.css'

class ViewCoupon extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }

    render(){
        return (
            <div className='couponsTable'>
            <table id='coupons'>
               <tbody>
                 <tr key={'Heading'} class='heading'>
                     <td>Coupon Code</td>
                     <td>Active</td>
                     <td>Discount</td>
                     <td>Coupon Type</td>
                     <td>Start Date</td>
                     <td>Expiry Date</td>
                 </tr>
                 
                 {this.props.coupons.map((coupon) => {
                    let { couponCode, active, coupon_type, discount_amount, start_date, expiry_date } = coupon
                     const date_time= expiry_date.split("T")
                     const date = date_time[0]
                     if(coupon_type.includes("MULTI")){
                         let attemptsLeft = coupon_type.split("_")
                         coupon_type = attemptsLeft[0] + " (" + attemptsLeft[1] + " )"
                     }
                     else if(coupon_type == "ONE_TIME"){
                         coupon_type = "SINGLE USE"
                     }
                    
                     
                     return (
                        <tr key={couponCode}>
                           <td>{couponCode}</td>
                           <td>{active? "Active": "Inactive"}</td>
                           <td>{discount_amount}</td>
                           <td>{coupon_type}</td>
                           <td>{start_date.split("T")[0]}</td>
                           <td>{date}</td>  
                                          
                        </tr>
                        )
                    })}   
               </tbody>
            </table>
            </div>
        )

    }
    
}

export default ViewCoupon;
import React from 'react';
import AuthService from '../Authentication/AuthService';
import logo from '../../Logos/aelen-logo.png'

import './HeaderAelen.css'


class HeaderAelen extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            showMenuOptions: false,
            user: {},
            showHamburger: false,
        }
    }
    async componentDidMount(){
        
        if(AuthService.getCurrentUser()){
            this.setState({showHamburger: true})
            const user = await AuthService.getCurrentUser();
            this.setState({ user: user });
        }
    }

    
    showMenu = ()=>{
        let showMenuOptions =! this.state.showMenuOptions
        this.setState({
            showMenuOptions: showMenuOptions
        })
    }
    render(){
       
        
        return (
            <>
            <div class='headerContainerAelen'>
                
                <div class='headerMargin'>
                    <a href="/" class='logo'><img src={logo} alt={logo}></img></a>
                    {this.state.showHamburger && 
                    <div class="menu-btn" onClick= {()=>{this.showMenu()}}>
                        <div class="menu-btn__burger"></div>
                    </div>}
                    {this.state.showMenuOptions && <div className='menuOptions' >
                    <ul>
                        <li><div>Hi {this.state.user.name}</div></li>
                        <li><a href='/stations'>Stations</a></li>
                        <li><a href='/orders'>Transactions</a></li>
                        {(AuthService.checkIfUserIsDeveloper()||AuthService.checkIfUserIsAdmin())&& <>
                        <li><a href='/ticket'>Create Ticket</a></li>
                        <li><a href='/coupon'>Coupons</a></li>
                        </>}
                        <li><a href='/logout'>Sign Out</a></li>
                    </ul>
                </div>}
                </div>
            </div>
            </>
        )

    }
    
}

export default HeaderAelen;
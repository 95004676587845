import React from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import 'react-dropdown/style.css';
import authHeader from '../Authentication/auth-header';


class Ticket extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            options: ['Hardware', 'Software', 'Other']
        }
    }

    async componentDidMount(){
        this.get_user_machines_info();
    }

    sort_stations = (stations)=>{
        return stations.sort((a, b) => (a.serialNumber > b.serialNumber ? 1 : -1))
    }

    get_user_machines_info = () =>{
        axios({
            method: 'get',
            url: '/api/user/get_my_machines',
            headers: authHeader()
        }).then((response)=>{
            let machines = response.data
            machines = this.sort_stations(machines)
            this.setState({stationsInfo: machines})
        })
    }
   

    add_ticket = (event) =>{
        event.preventDefault();
        const elements = event.target.elements
        var formData = {}
       
        for(let element in elements){
            if(elements[element].name){
                formData[elements[element].name] = elements[element].value
            }
        }
        console.log(formData)
         axios({
             method: 'post',
             url: '/api/ticket/add_ticket',
             headers: authHeader(),
             data: JSON.stringify(formData)

         }).then((response)=>{
             alert("Ticket Submitted")
             window.location.replace('/')
         }).catch(err=>{
             alert("Add Ticket failed")
         })
    }
    render(){
        const options =  ['Hardware', 'Software', 'Other']
        let stations = []
        let issueTypes = options.map(typeOfIssue =>{ 
            return (<option key={typeOfIssue} value={typeOfIssue}>{typeOfIssue}</option>)})
        if(this.state.stationsInfo){
            stations = this.state.stationsInfo.map(station=>{
                return (<option key={station['serialNumber']} value={station['serialNumber']}>{station['location']}</option>)
            })
        }
        
        return (
            
                    <div className='addProduct'>
                        <Form onSubmit={this.add_ticket}>
                            <Form.Group controlId="formBasicSelect" >
                                <Form.Label>Machine Location</Form.Label>
                                <Form.Control as="select" name="MachineSerialNumber" onChange={()=>{}}>
                                    {stations}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formBasicSelect" >
                                <Form.Label>Type of Issue</Form.Label>
                                <Form.Control as="select" name="issueType" onChange={()=>{}}>
                                    {issueTypes}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>       
                                <Form.Label>Issue Subject</Form.Label>
                                <Form.Control as="textarea" name="subject"/>
                            </Form.Group>       
                            <Form.Group>       
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={5}  name="description"/>
                            </Form.Group> 
                            <Button variant="primary" type="submit">
                                Create Ticket
                            </Button>
                            
                        </Form>
                    </div>
        )

    }
    
}

export default Ticket;
import axios from 'axios';
import React from 'react';
  
class Upload extends React.Component {
  
    constructor(props){
      super(props)
      this.state = {
        // Initially, no file is selected
      selectedFile: null
      }
  }
    // On file select (from the pop up)
    onFileChange = event => {
    
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
    
    };
    
    // On file upload (click the upload button)
    onFileUpload = () => {
      // Create an object of formData
      const formData = new FormData();
    
      // Update the formData object
      formData.append(
        "photo",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
      formData.append("MachineSerialNumber",this.props.serialNumber)
      formData.append("Port",this.props.port)
    
      // Request made to the backend api
      // Send formData object
      axios({
        method: 'post',
        url: '/api/machine_data/upload',
        data:formData
    }).then(response=>{
      console.log(response)
        alert(response.data)
    }).catch(err=>{
      console.log(err)
        alert("Some error occured! Image is not uploaded")
    })
      
      this.props.handleClose();
    };
    
    // File content to be displayed after
    // file upload is complete
    fileData = () => {
    
      if (this.state.selectedFile) {
         
        return (
          <div> 
            <p>File Name: {this.state.selectedFile.name}</p>
          </div>
        );
      } else {
        return (
          <div>
            <h4>Select an image first</h4>
          </div>
        );
      }
    };
    
    render() {
    
      return (
        <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={this.props.handleClose}>x</span>
                    <div className='productsTable'>
                <input type="file" onChange={this.onFileChange} />
                {this.state.selectedFile && <button onClick={this.onFileUpload}>
                  Upload Image!
                </button>}
            </div>
          {this.fileData()}
        </div>
        </div>
      );
    }
  }
 
  export default Upload;
import React from 'react';
import { CSVLink } from 'react-csv';
import './couponOrders.css'

class CouponOrder extends React.Component {
    constructor(props){
        super(props)
        this.myRef = React.createRef()  
        this.state = {
            currentSort: 'down',
        }
    }

    componentDidMount() {
    //   this.myRef.scrollIntoView()
    }
   
    
    onSortChange = () => {
		const { currentSort } = this.state;
		let nextSort;
		if (currentSort === 'down') nextSort = 'up';
		else if (currentSort === 'up') nextSort = 'down';
		this.setState({
			currentSort: nextSort
		});
	};
   
    render(){
      const sortTypes = {
         up: {
            class: 'sort-up',
            fn: (a, b) => new Date(a.start_time) - new Date(b.start_time)
         },
         down: {
            class: 'sort-down',
            fn: (a, b) => new Date(b.start_time) - new Date(a.start_time)
         },
         default: {
            class: 'sort',
            fn: (a, b) => a
         }
      };
      const { currentSort } = this.state;
      let exportOrder = []
      for(var i in this.props.orders){
         let order = this.props.orders[i]
         var individualOrder = {
            'location': this.props.location,
            'start_time': order['start_time'],
            'contents':order['contents'], 
            'price': order['price'],
            'orderStatus': order['orderStatus'] 
         }
         exportOrder.push(individualOrder)
      
      }
      let headers = [
         { label: "location", key: "location" },
         { label: "start_time", key: "start_time" },
         { label: "contents", key: "contents" },
         { label: "quantity requested", key: "quantity_requested" },
         { label: "quantity dispensed", key: "quantity_dispensed" },
         { label: "price", key: "price" },
         { label: "orderStatus", key: "orderStatus"}
     ];
           return (
            <div>
               <div ref={ (ref) => this.myRef=ref }></div>
               <div className="infoRow">
                  <div className='ownerInfo'>
                    Coupon Code:  {this.props.orders[0].CouponCouponCode}" Logs
                  </div>
                  <div className="btn-groups">
                      <button className="btn btn-main" onClick={this.props.hideFunction}>Close</button>
                     {/* <button onClick={this.onSortChange} className="btn btn-main">
                        Sort By Date
                     </button> &ensp;
                     <button className="btn btn-main">
                        <CSVLink
                           data={exportOrder}
                           headers = {headers}
                           filename='orders-data'
                           style={{ "textDecoration": "none", "color": "#fff" }}
                           > Export Data
                        </CSVLink>
                     </button>   */}
                  </div>
               </div>
            <div className='transactionTable'>
                            
                  <table id='orders'>
                  <tbody>
                    <tr key={'Heading'} class='heading'>
                        <td className="header_location">Location</td>
                        <td>Date</td>
                        <td>Time</td>
                        <td>Product</td>
                        <td>Quantity Requested</td>
                        <td>Quantity Dispensed</td>
                        <td>Price<span className="header_quantitySize"> ($)</span></td>
                        <td className="header_status">Status</td>
                    </tr>
                    
                    {this.props.orders.sort(sortTypes[currentSort].fn).map((order, index) => {
                       
                        let { orderId, start_time, contents, price, quantity_requested, quantity_dispensed, orderStatus, MachineSerialNumber } = order
                        if(contents){
                           contents = contents.replace("<br>", " ")
                       }
                        const date_time= start_time.split("T")
                        const date = date_time[0]
                        let time = date_time[1].split('.')
                        time = time[0]
                        
                        price = parseFloat(price).toFixed(2)
                        let status 
                        if(orderStatus.length===9){
                           status = "Order Created"
                        }else{
                           orderStatus.at(-1) === "InProgress" ? status="In Progress": status=orderStatus.at(-1)
                        }
                        return (
                           <tr key={orderId} class="tableOrdersRow">
                              <td>{MachineSerialNumber}</td>
                              <td>{date}</td>
                              <td>{time}</td>
                              <td>{contents}</td>
                              <td>{quantity_requested}</td>
                              <td>{quantity_dispensed}</td>
                              <td>{price}</td>
                              <td>{status}</td>
                           </tr>
                        )
                     }
                    )
                  }
                  </tbody>
               </table>
               </div>
            </div>
         )}
        }
    
    
    


export default CouponOrder;
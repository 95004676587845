import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dropdown/style.css';
import './Product.css'
import logo from '../../Logos/Soapstand-icon-wordmark-teal.png'
import PreviewProduct from './PreviewProduct';
import Select, { components } from "react-select";
import { FaSearch } from "react-icons/fa";

class ChangeProduct extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            changeProduct: false,
            selectedOption: "",
            previewProduct : false,
            productToPreview : "",
            defaultPlaceholder : "Select product"
        }
    }
    
    previewProduct = ()=>{
        this.setState({previewProduct : !this.state.previewProduct})
    }
    handleChange = (event)=> {
        this.setState({productToPreview: event["Product ID"], defaultPlaceholder: event["Product Name on UI"]});
    }
    render(){
        const myDict = this.props.localproducts
        
        const colorStyles = {
            control: (styles) => ({ ...styles, '&:hover': { borderColor: '(--text-color--primary' }, boxShadow: `0 0 0 1px '(--text-color--primary'`, backgroundColor: "#fff", border: '1px solid #327079', fontSize: '1.2rem', borderRadius: '1rem', padding: '.2rem .7rem', zIndex:'1', marginTop: '4rem'}),
            placeholder: (styles) => ({ ...styles, color: 'var(--text-color--primary'}), 
            input: (styles) => ({...styles, color: 'var(--text-color--primary'}),
            option: (styles, {data, isDisable, isFocused, isSelected}) => {
                return{...styles, 
                        color: "var(--text-color--primary", 
                        padding: '.75rem 2rem', 
                        backgroundColor:"#fff", 
                        "&:hover, &:focus, &:active, &:visited":{backgroundColor: 'rgba(120, 170, 178, 0.4) !important', color: 'var(--text-color--secondary)'}, 
                        borderBottom:".25px solid var(--text-color--primary"
                    }
            }
        }
          
        const test = this.props.localproducts 
        const items = Object.keys(test)
        const options = []
        for(const item in items){
            if(test[items[item]]["Product Name on UI"]){
                const object = test[items[item]]
                Object.assign(object, {label: test[items[item]]["Product Name on UI"]})
                options.push(test[items[item]]);
            }
        }

        const DropdownIndicator = props => {
            return (
              <components.DropdownIndicator {...props}>
                <FaSearch className="searchIcon" />
              </components.DropdownIndicator>
            );
          };

         return (
            <div className="popup-box preview-popup">
                <div className="preview-box search-product-box">
                    <div className='previewProduct'>
                        <span className="close-icon preview-close" onClick={(this.props.handleClose)}>x</span>
                        <img className="previewProduct-logo" src={logo} alt={logo}></img>
                        {/* <select className="selectBar" value={this.state.productToPreview} menuIsOpen onChange={(this.handleChange)} placeholder="please select a product">
                            {dictItems.map(item =>(
                                myDict[item]["Product Name on UI"] && <option className="selectOption" value={item} key={item}>{myDict[item]["Product Name on UI"]}</option>
                            ))}
                        </select> */}
                        <Select
                            getOptionLabel={option => option["Product Name on UI"]}
                            getOptionValue={option => option["Product ID"]}
                            components={{DropdownIndicator }}
                            value={this.state.productToPreview}
                            onChange={(this.handleChange)}
                            options={options}
                            placeholder={this.state.defaultPlaceholder}
                            styles={colorStyles}
                            // menuIsOpen={true}
                        />
                        <button className="btn btn-main btn-preview" type="button" style={{ marginRight: 10}} onClick={(this.previewProduct)}>Preview</button>                    
                        {(this.state.previewProduct && this.state.productToPreview!="") && <PreviewProduct handleClose={this.previewProduct} localproducts={myDict} localProductId={this.state.productToPreview} selectButton={true} productId={this.props.productId}/>}

                    </div>
                </div>
            </div>
        
        )

    }
    
}

export default ChangeProduct;
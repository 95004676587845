import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-dropdown/style.css';


class ShowProductDetails extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }

    render(){
     
        return (
            <div className="popup-box">
                <div className="box">
                    <span className="close-icon" onClick={this.props.handleClose}>x</span>
                    <div className='productsTable'>
                        <table id='products'>
                        <tbody>
                            <tr key={'Heading'} class='heading'>
                                <td>Product Name</td>
                                <td>Price(Small)</td>
                                <td>Price(Large)</td>
                                <td>Size(Small)</td>
                                <td>Size(large) </td>
                                <td>Product Id</td>
                                <td>Soapstand id</td>
                                <td>Size</td>
                            </tr>
                            {this.props.products.map((product) => { 
                                let { name, price_small, price_large, size_small, size_large, productId,soapstand_product_serial,size_unit } = product
                                return (
                                    <tr key={productId}>
                                    <td>{name}</td>
                                    <td>{price_small}</td>
                                    <td>{price_large}</td>
                                    <td>{size_small}</td>
                                    <td>{size_large}</td>
                                    <td>{productId}</td>
                                    <td>{soapstand_product_serial}</td>
                                    <td>{size_unit}</td>
                                    
                                    </tr>
                                )})}
                        </tbody>
                        </table>
                        </div>
                </div>
                
            </div>
        )

    }
    
}

export default ShowProductDetails;
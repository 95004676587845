import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dropdown/style.css';
import '../Product/Product.css'

import axios from 'axios';
import authHeader from '../Authentication/auth-header';
import moment from "moment";
import logoIcon from '../../Logos/Soapstand-icon-white.png'

class StationWifi extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            stationWifi: false,
            stationsWifiStatus: []        }

    }

        
    async componentDidMount(){
        this.wifi(this.props.stationId); 
    }

    wifi = (stationId)=>{
        axios({
            method: 'get',
            url: `/api/stationStatus/fetch/${stationId}`,
            headers: authHeader(),
        }).then((response)=>{
            let allLogs = []
            response.data.forEach((log)=>{
                allLogs.push(log);
            })
            
            this.setState({stationsWifiStatus: allLogs})
        }).catch(err=>{
            // console.log(err)
            alert("error")
        })
    }

    render(){
        const listItems = this.state.stationsWifiStatus.map((station) =>{
            if(station["wifiStrength"] != "{}" &&  station["wifiStrength"] != "Wifi is not connected"){
                let lvls = ["____","▂___","▂▄__","▂▄▆_","▂▄▆█"];
                let styleLevel = ['level0', 'level1', 'level2', 'level3', 'level4']
                let stationWifiList = station["wifiStrength"].split(":")
                let levelValue = lvls.findIndex(obj => obj == stationWifiList[6]);
                let levelCSS=styleLevel[levelValue];
                const utcTime = station["updatedAt"];
                return(                    
                <tr className="tableRow wifiLog">
                    <td className="tableContent tableUpdated">{moment(utcTime).format('MMM Do YYYY, h:mm:ss a')}</td>
                    <td className="tableContent tableName">{stationWifiList[1]}</td>
                    {/* <td className="tableContent tableBit">{stationWifiList[4]}</td> */}
                    <td className="tableContent tableTemperature">{station['temperature_1']==666? "N/A": station['temperature_1']}</td>
                    <td className="tableContent tableTemperature">{station['temperature_2']==666? "N/A": station['temperature_2']}</td>

                    {/* <td className={`tableContent tableSignal ${levelCSS}`}>{lvls[levelValue]}</td> */}
                    <td className={`tableContent tableSignal ${levelCSS}`}>

                        <div className="bars">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </td>
                </tr>
                )

            }
        }


      );
        return (
            <div className="popup-box wifiStatus-popup">
                {this.state.stationsWifiStatus.length ?
                <div className="wifiStatus-box">
                    <span className="close-icon wifiStatus-close" onClick={(this.props.handleClose)}>x</span>
                        <h3 className="wifiStatusTitle">Wifi Status Log</h3>
                        <table className="wifiStatus">
                            <tr className="tableHeaderRow">
                                <th className="tableHeader updateHeader">Updated At</th>
                                <th className="tableHeader wifiName">Wifi Name</th>
                                {/* <th className="tableHeader bitRate">Bit Rate</th> */}
                                <th className="tableHeader tableTemperature">Temperature (Fridge)</th>
                                <th className="tableHeader tableTemperature">Temperature (Cavity)</th>
                                <th className="tableHeader signalStrength">Wifi Strength</th>
                            </tr>
                            {listItems}
                        </table>
                </div>
                : <div className="wifiStatus-box wifiUnavailable-box">
                    <span className="close-icon wifiStatus-close" onClick={(this.props.handleClose)}>x</span>
                    <div className="wifiUnavailable-header">
                        <img className="wifiUnavailable-logo" src={logoIcon} alt={logoIcon}></img>
                        <h3 className="wifiUnavailable-headerText">SORRY</h3>
                    </div>
                    <div className="wifiUnavailable-contentText">
                        No Station Wifi Information available
                    </div>
                </div>}
            </div>
        )

    }
}
export default StationWifi;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import axios from "axios";
import "react-dropdown/style.css";
import "./EditProduct.css";
import PreviewProduct from "../Product/PreviewProduct";
import ChangeProduct from "../Product/ChangeProduct";
import authHeader from "../Authentication/auth-header";

class EditProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewProduct: false,
      changeProduct: false,
      showing: false,
      availabilityStatus: "",
      currentStatus: "For Sale", // Default Status (for now!)
    };
  }

  previewProduct = () => {
    this.setState({ previewProduct: !this.state.previewProduct });
  };

  changeProduct = () => {
    this.setState({ changeProduct: !this.state.changeProduct });
  };

  async componentDidMount() {
    // this.get_local_products();

    this.checkPID();
    let slotNumber = this.props.product["slot"];
    let slotName = "is_enabled_slot_" + slotNumber;
    let currentlyAvailable = this.props.machine[slotName];

    if (currentlyAvailable) {
      this.setState({ availabilityStatus: "Mark as Unavailable" });
    } else {
      this.setState({ availabilityStatus: "Mark as Available" });
    }
  }

  get_local_products = () => {
    axios({
      method: "get",
      url: `/api/product/get_local_products`,
      headers: authHeader(),
    })
      .then((response) => {
        this.setState({ local_products: response["data"] });
      })
      .catch((err) => {
        alert("Unable to fetch product information from database");
        console.error(err);
      });
  };
  edit_product = (event) => {
    event.preventDefault();

    const confirmed = window.confirm("Please confirm station update!");

    if (confirmed) {
      const elements = event.target.elements;
      var formData = {};
      for (let element in elements) {
        if (elements[element].name) {
          formData[elements[element].name] = elements[element].value;
          if (elements[element].type == "number") {
            formData[elements[element].name] = Number(elements[element].value);
          }
          if (
            elements[element].name == "name1" ||
            elements[element].name == "name2"
          ) {
            formData["name"] =
              elements["name1"].value + " <br> " + elements["name2"].value;
          }
        }
      }
      if (elements["size_unit"].value == "oz") {
        formData["size_small"] *= 29.5735;
        formData["size_medium"] *= 29.5735;
        formData["size_large"] *= 29.5735;
        formData["size_custom_min"] *= 29.5735;
        formData["size_custom_max"] *= 29.5735;
        formData["volume_remaining"] *= 29.5735;
      }
      axios({
        method: "post",
        url: `/api/product/update_product_info`,
        data: formData,
        headers: authHeader(),
      })
        .then((response) => {
          alert("Product Information Updated. ");
          // window.location.reload(false);
        })
        .catch((err) => {
          alert(
            "An error occured!! Product will not be updated at this time"
          );
          console.error(err);
        });
    }
  };

  //  Is checkPID necessary?

  checkPID = () => {
    if (this.props.product.pid_small != 0) {
      this.setState({ showSPID: true });
    } else {
      this.setState({ showSPID: false });
    }
    if (this.props.product.pid_large != 0) {
      this.setState({ showLPID: true });
    } else {
      this.setState({ showLPID: false });
    }
  };

  markUnavailable = (event) => {
    console.log(event.target.value);
    event.preventDefault();

    // Update current status based on selected button
    // this.setState({ currentStatus: event.target.value });

    let slotNumber = this.props.product["slot"];
    let serialNumber = this.props.product["MachineSerialNumber"];
    let slotName = "is_enabled_slot_" + slotNumber;
    // let currentlyAvailable = this.props.machine[slotName]
    let slotText = "status_text_slot_" + slotNumber;
    let slotTextValue = event.target.value;
    let newProductStatus = false;
    axios({
      method: "post",
      url: `/api/machine/update`,
      data: {
        serialNumber: serialNumber,
        [slotName]: newProductStatus,
        [slotText]: slotTextValue,
      },
      headers: authHeader(),
    })
      .then((response) => {
        if (newProductStatus) {
          this.setState({ availabilityStatus: "Mark as Available" });
          alert(
            "Product marked as available. Please 'Save Changes' to push your changes to station"
          );
        } else {
          this.setState({ availabilityStatus: "Mark as Unavailable" });
          alert(
            "Product Marked as Unavailable. Please 'Save Changes' to push your changes to station"
          );
        }
      })
      .catch((err) => {
        alert("An error occured!! Product will not be updated at this time");
      });
  };

  handleClose = () => {
    if (this.state.isFormDirty) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (!confirmLeave) {
        return;
      }
    }
    this.props.handleClose();
  };

  render() {
    const product = this.props.product;
    const productName = product.name.split("<br>");

    return (
      <div className="popup-box">
        <div className="box">
          <span className="close-icon" onClick={this.handleClose}>
            x
          </span>
          <h1>Edit Product Information</h1>
          <Form onSubmit={this.edit_product}>
            <div className="Product-Info">
              <div className="info-container">
                <Form.Group style={{ display: "none" }}>
                  <Form.Label>Product Id</Form.Label>
                  <Form.Control
                    id="PID-form"
                    disabled
                    type="text"
                    placeholder={this.props.product.productId}
                    name="productId"
                    defaultValue={product.productId}
                  />
                </Form.Group>
                {!this.props.product.soapstand_product_serial ? (
                  <></>
                ) : (
                  <div className="product-names">
                    <div className="name-container">
                      <Form.Group>
                        <Form.Label>Product Name Line 1</Form.Label>
                        <Form.Control
                          className="product-name-form"
                          type="text"
                          defaultValue={productName[0]}
                          name="name1"
                          disabled={true}
                        />
                      </Form.Group>
                    </div>
                    <div className="name-container">
                      <Form.Group>
                        <Form.Label>Product Name Line 2</Form.Label>
                        <Form.Control
                          className="product-name-form"
                          type="text"
                          defaultValue={productName[1]}
                          name="name2"
                          disabled={true}
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="change-btn">
                      <button
                        className="btn btn-main"
                        type="button"
                        style={{ marginRight: 10 }}
                        onClick={this.changeProduct}
                      >
                        Change Product
                      </button>
                    </div> */}
                  </div>
                )}
              </div>
              {/* <div
                id="result"
                className="status-container"
                onChange={this.markUnavailable}
              >
                <label className="radio-label">
                  <input
                    type="radio"
                    className="radio-btn"
                    id="statusForSale"
                    name="radio"
                    value="For Sale"
                    checked={this.state.currentStatus === "For Sale"}
                  />
                  <span>For Sale</span>
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    className="radio-btn"
                    id="statusComingSoon"
                    name="radio"
                    value="Coming Soon"
                    checked={this.state.currentStatus === "Coming Soon"}
                  />
                  <span>Coming Soon</span>
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    className="radio-btn"
                    id="statusSoldOut"
                    name="radio"
                    value="Sold Out"
                    checked={this.state.currentStatus === "Sold Out"}
                  />
                  <span>Sold Out</span>
                </label>

                {/* <button
                  id="preview-display-btn"
                  className="btn btn-main"
                  type="button"
                  style={{ marginRight: 10 }}
                  onClick={this.previewProduct}
                >
                  Preview Display
                </button> 
              </div> */}
            </div>
            <div className="quantity-options">
              <div className="unit-container">
                <h2 id="options-header">Quantity Options</h2>
                <div className="volume-row">
                  <Form.Group
                    controlId="formBasicSelect"
                    className="volume-remaining-form"
                  >
                    <Form.Label>
                      <h3>Volume Remaining</h3>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      step="any"
                      name="volume_remaining"
                      defaultValue={
                        product.size_unit == "oz"
                          ? (product.volume_remaining / 29.5735).toFixed()
                          : (product.volume_remaining / 1).toFixed()
                      }
                      disabled={!this.state.editVolRemaining}
                      onChange={() => this.setState({ isFormDirty: true })}
                    ></Form.Control>
                    <div className="update-vol">
                      <Form.Label>Edit Volume Fields</Form.Label>
                      <Form.Check
                        type="checkbox"
                        style={{ marginLeft: "8rem" }}
                        onClick={() =>
                          this.setState({
                            editVolRemaining: !this.state.editVolRemaining,
                          })
                        }
                      />
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="formBasicSelect"
                    className="volume-unit-form"
                  >
                    <Form.Label>
                      <h3>Volume Unit</h3>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="size_unit"
                      defaultValue={product.size_unit}
                      onChange={() => this.setState({ isFormDirty: true })}
                      disabled={!this.state.editVolRemaining}
                    >
                      <option value="ml">ml</option>
                      <option value="oz">oz (Ounces)</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>

              <div className="product-options-grid">
                {/* headers */}
                <div>
                  <strong>Name</strong>
                </div>
                <div>
                  <strong>Volume</strong>
                </div>
                <div>
                  <strong>Price</strong>
                </div>
                <div className="pid-column">
                  <strong>Product ID</strong>
                  <label className="btn-toggle">
                    <input
                      className="btn-toggleCheckbox"
                      type="checkbox"
                      defaultChecked={this.state.showPID}
                      onClick={() =>
                        this.setState({ showPID: !this.state.showPID })
                      }
                    />
                    <span className="btn-toggleSpan" />
                  </label>
                </div>
                <div>
                  <strong>Edit</strong>
                </div>

                {/* Small Option */}
                <div className="product-option" id="small-title">
                  <Form.Group>
                    <Form.Control defaultValue="Small" disabled={true} />
                  </Form.Group>
                </div>

                <div className="product-option" id="small-volume">
                  <Form.Group>
                    <Form.Control
                      type="number"
                      name="size_small"
                      step="any"
                      onChange={() => this.setState({ isFormDirty: true })}
                      disabled={!this.state.editSmall}
                      defaultValue={
                        product.size_unit == "oz"
                          ? (product.size_small / 29.5735).toFixed()
                          : product.size_small
                      }
                    />
                  </Form.Group>
                </div>

                <div className="product-option" id="small-price">
                  <Form.Group>
                    <Form.Control
                      type="number"
                      name="price_small"
                      defaultValue={product.price_small}
                      step="any"
                      onChange={() => this.setState({ isFormDirty: true })}
                      disabled={!this.state.editSmall}
                    />
                  </Form.Group>
                </div>

                <div className="product-option" id="small-pid">
                  {this.state.showPID ? (
                    <>
                      <Form.Control
                        type="number"
                        name="pid_small"
                        defaultValue={product.pid_small}
                        disabled={true}
                        onChange={() => this.setState({ isFormDirty: true })}
                      />
                    </>
                  ) : null}
                </div>

                <div className="product-option" id="small-edit">
                  <Form.Group>
                    <Form.Check
                      type="checkbox"
                      onClick={() =>
                        this.setState({ editSmall: !this.state.editSmall })
                      }
                    />
                  </Form.Group>
                </div>

                {/* Medium Option */}
                {this.state.showing && (
                  <>
                    <div className="product-option" id="medium-title">
                      <Form.Group>
                        <Form.Control defaultValue="Medium" disabled={true} />
                      </Form.Group>
                    </div>

                    <div className="product-option" id="medium-volume">
                      <Form.Group>
                        <Form.Control
                          type="number"
                          name="size_medium"
                          step="any"
                          disabled={!this.state.editMedium}
                          defaultValue={
                            product.size_unit == "oz"
                              ? (product.size_medium / 29.5735).toFixed()
                              : product.size_medium
                          }
                          onChange={() => this.setState({ isFormDirty: true })}
                        />
                      </Form.Group>
                    </div>

                    <div className="product-option" id="medium-price">
                      <Form.Group>
                        <Form.Control
                          type="number"
                          name="price_medium"
                          defaultValue={product.price_medium}
                          step="any"
                          disabled={!this.state.editMedium}
                          onChange={() => this.setState({ isFormDirty: true })}
                        />
                      </Form.Group>
                    </div>

                    <div className="product-option" id="medium-pid"></div>

                    <div className="product-option" id="medium-edit">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          onClick={() =>
                            this.setState({
                              editMedium: !this.state.editMedium,
                            })
                          }
                        />
                      </Form.Group>
                    </div>
                  </>
                )}

                {/* Large Option */}
                <div className="product-option" id="large-title">
                  <Form.Group>
                    <Form.Control defaultValue="Large" disabled={true} />
                  </Form.Group>
                </div>

                <div className="product-option" id="large-volume">
                  <Form.Group>
                    <Form.Control
                      type="number"
                      name="size_large"
                      step="any"
                      disabled={!this.state.editLarge}
                      defaultValue={
                        product.size_unit == "oz"
                          ? (product.size_large / 29.5735).toFixed()
                          : product.size_large
                      }
                      onChange={() => this.setState({ isFormDirty: true })}
                    />
                  </Form.Group>
                </div>

                <div className="product-option" id="large-price">
                  <Form.Group>
                    <Form.Control
                      type="number"
                      name="price_large"
                      defaultValue={product.price_large}
                      step="any"
                      disabled={!this.state.editLarge}
                      onChange={() => this.setState({ isFormDirty: true })}
                    />
                  </Form.Group>
                </div>
                <div className="product-option" id="large-pid">
                  {this.state.showPID ? (
                    <>
                      <Form.Control
                        type="number"
                        name="pid_large"
                        defaultValue={product.pid_large}
                        disabled={true}
                        onChange={() => this.setState({ isFormDirty: true })}
                      />
                    </>
                  ) : null}
                </div>

                <div className="product-option" id="large-edit">
                  <Form.Group>
                    <Form.Check
                      type="checkbox"
                      onClick={() =>
                        this.setState({ editLarge: !this.state.editLarge })
                      }
                    />
                  </Form.Group>
                </div>

                {/* Custom Min Option */}
                {this.state.showing && (
                  <>
                    <div className="product-option" id="custom-title">
                      <Form.Group>
                        <Form.Control
                          defaultValue="Custom Min"
                          disabled={true}
                        />
                      </Form.Group>
                    </div>

                    <div className="product-option" id="custom-min-volume">
                      <Form.Group>
                        <Form.Control
                          type="number"
                          name="size_custom_min"
                          step="any"
                          disabled={!this.state.editCustom}
                          defaultValue={
                            product.size_unit == "oz"
                              ? (product.size_custom_min / 29.5735).toFixed()
                              : product.size_custom_min
                          }
                          onChange={() => this.setState({ isFormDirty: true })}
                        />
                      </Form.Group>
                    </div>

                    <div className="product-option" id="custim-min-price">
                      <Form.Group>
                        <Form.Control
                          type="number"
                          name="price_custom_min"
                          defaultValue={product.price_custom_min}
                          step="any"
                          disabled={!this.state.editCustom}
                          onChange={() => this.setState({ isFormDirty: true })}
                        />
                      </Form.Group>
                    </div>

                    <div className="product-option" id="custom-min-pid"></div>

                    <div className="product-option" id="custom-edit">
                      <Form.Group>
                        <Form.Check
                          type="checkbox"
                          onClick={() =>
                            this.setState({
                              editCustom: !this.state.editCustom,
                            })
                          }
                        />
                      </Form.Group>
                    </div>

                    {/* Custom Max Option */}
                    <div className="product-option" id="custom-title">
                      <Form.Group>
                        <Form.Control
                          defaultValue="Custom Max"
                          disabled={true}
                        />
                      </Form.Group>
                    </div>

                    <div className="product-option" id="custom-max-volume">
                      <Form.Group>
                        <Form.Control
                          type="number"
                          name="size_custom_max"
                          step="any"
                          disabled={!this.state.editCustom}
                          defaultValue={
                            product.size_unit == "oz"
                              ? (product.size_custom_max / 29.5735).toFixed()
                              : product.size_custom_max
                          }
                          onChange={() => this.setState({ isFormDirty: true })}
                        />
                      </Form.Group>
                    </div>

                    <div className="product-option" id="custim-max-price">
                      <Form.Group>
                        <Form.Control
                          type="number"
                          name="price_custom_max"
                          defaultValue={product.price_custom_max}
                          step="any"
                          disabled={!this.state.editCustom}
                          onChange={() => this.setState({ isFormDirty: true })}
                        />
                      </Form.Group>
                    </div>
                  </>
                )}

                <div className="product-option" id="custom-max-pid"></div>
                <div className="product-option" id="custom-max-edit"></div>
              </div>
            </div>
            <div className="footer">
              <div className="show-more-container">
                <h3>Show More Options</h3>
                <label className="btn-toggle">
                  <input
                    className="btn-toggleCheckbox"
                    type="checkbox"
                    defaultChecked={this.state.showing}
                    onClick={() =>
                      this.setState({ showing: !this.state.showing })
                    }
                  />
                  <span className="btn-toggleSpan" />
                </label>
              </div>

              <button className="update-db">SAVE CHANGES</button>
            </div>

            {this.state.previewProduct && (
              <PreviewProduct
                handleClose={this.previewProduct}
                localproducts={this.state.local_products}
                localProductId={product.soapstand_product_serial}
                selectButton={false}
                productId={this.props.productId}
              />
            )}

            {this.state.changeProduct && (
              <ChangeProduct
                handleClose={this.changeProduct}
                localproducts={this.state.local_products}
                localProductId={product.soapstand_product_serial}
                productId={product.productId}
              />
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default EditProduct;

import React, { Component } from 'react';
import axios from 'axios';

class CleaningLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      newLog: {
        date: '',
        time: '',
        purpose: '',
        comments: '',
        cleanedBy: '',
        externalCleaning: '',
        internalCleaning: '',
        productChange: ''
      }
    };
  }

  componentDidMount() {
    this.fetchCleaningLogs(this.props.stationId);
  }

  fetchCleaningLogs = async (stationId) => {
    try {
      const response = await axios.get(`/api/cleaningLog/fetch/${stationId}`);
      this.setState({ logs: response.data });
    } catch (error) {
      console.error('Error fetching cleaning logs:', error);
    }
  };

  addCleaningLog = async () => {
    try {
      await axios.post('/api/cleaningLog/add', this.state.newLog);
      this.fetchCleaningLogs();
      // Reset newLog state to clear the form
      this.setState({
        newLog: {
          date: '',
          time: '',
          purpose: '',
          comments: '',
          cleanedBy: '',
          externalCleaning: '',
          internalCleaning: '',
          productChange: ''
        }
      });
    } catch (error) {
      console.error('Error adding cleaning log:', error);
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      newLog: {
        ...prevState.newLog,
        [name]: value
      }
    }));
  };

  render() {
    return (
      <div className="cleaning-log">
        <span className="close-icon cleaning-log-close" onClick={(this.props.handleClose)}>x</span>
        <h2>Cleaning Log</h2>
        <div className="log-form">
          <input type="date" name="date" value={this.state.newLog.date} onChange={this.handleInputChange} />
          <input type="text" name="time" placeholder="Time" value={this.state.newLog.time} onChange={this.handleInputChange} />
          <input type="text" name="purpose" placeholder="Purpose" value={this.state.newLog.purpose} onChange={this.handleInputChange} />
          <input type="text" name="comments" placeholder="Comments" value={this.state.newLog.comments} onChange={this.handleInputChange} />
          <input type="text" name="cleanedBy" placeholder="Cleaned by" value={this.state.newLog.cleanedBy} onChange={this.handleInputChange} />
          <input type="text" name="externalCleaning" placeholder="External Cleaning" value={this.state.newLog.externalCleaning} onChange={this.handleInputChange} />
          <input type="text" name="internalCleaning" placeholder="Internal Cleaning" value={this.state.newLog.internalCleaning} onChange={this.handleInputChange} />
          <input type="text" name="productChange" placeholder="Product Change" value={this.state.newLog.productChange} onChange={this.handleInputChange} />
          <button onClick={this.addCleaningLog}>Add Log</button>
        </div>
        <div className="log-list">
          <h3>Recent Logs</h3>
          <ul>
            {this.state.logs.map(log => (
              <li key={log._id}>
                <span>{log.date}</span>
                <span>{log.time}</span>
                <span>{log.purpose}</span>
                <span>{log.comments}</span>
                <span>{log.cleanedBy}</span>
                <span>{log.externalCleaning}</span>
                <span>{log.internalCleaning}</span>
                <span>{log.productChange}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default CleaningLog;

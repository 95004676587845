import React from 'react';
import logo from '../../Logos/Soapstand-icon-wordmark-teal.png'
import Header from '../Header/Header'
import AuthService from '../Authentication/AuthService';
import Login from '../Authentication/Login';
import Station from '../Station/Station'
import './Homepage.css'



class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUser: false,
      showAdmin: false,
      showClient: false,
      showSubscriber: false,
      currentUser: undefined,
    };
    console.log()
  }

  async componentDidMount() {
    const user = await AuthService.getCurrentUser();
    
    if (user) {
      this.setState({
        currentUser: user,
        showAdmin: user.permissions.includes('admin'),
        showClient: user.permissions.includes('client'),
        showSubscriber: user.permissions.includes('Subscriber'),
      });
    }
  }

  render() {
    const { currentUser, showAdmin } = this.state;
    const tokenExpired =  AuthService.checkIfTokenExpired();

    return (
      <>
        <Header />
        {this.props.pageType=="initial" &&
        <>
        {tokenExpired ? (
          <div className='homepageContainer'>
            <img src={logo} alt={logo} />
            <div className='customerPortal'>customer portal</div>
            <Login id='login' />
          </div>
        ) : (
          <>
            {showAdmin && currentUser ? (
              <Station info={{ view: 'admin', userId: currentUser }} />
            ) : (
              currentUser && (
                <Station info={{ view: 'client', userId: currentUser }} />
              )
            )
            }
          </>
        )}
        </>
          }
      </>
    );
  }
}
export default Homepage;
import React from "react";
import "./TempStatus.css";
import axios from "axios";
import authHeader from "../Authentication/auth-header";

class TempStatus extends React.Component {
  state = {
    stationsTempStatus: {},
    loading: false,
    tempIssues: [],
  };

  fetchAllStationsTempStatus = async (machines) => {
    this.setState({ loading: true });
    let promises = [];
    Object.values(machines).forEach((machineArray) => {
      machineArray.forEach((machine) => {
        promises.push(this.fetchTempStatus(machine.serialNumber));
      });
    });
    await Promise.all(promises);
    this.setState({ loading: false });

    window.alert("Temperatures Checked!");
  };

  fetchTempStatus = async (stationId) => {
    try {
      const response = await axios.get(
        `/api/stationStatus/fetch/${stationId}`,
        { headers: authHeader() }
      );
      const mostRecentLog = response.data[0] || {};
      if (
        parseInt(mostRecentLog.temperature_1) !== 666 &&
        mostRecentLog.temperature_1 > 4
      ) {
        this.addTempIssue("hotFridge", stationId);
        console.log(`${stationId} has a fridge temperature of ${mostRecentLog.temperature_1}°C!`);
        window.alert(
          `${stationId} has a fridge temperature of ${mostRecentLog.temperature_1}°C!`
        );
      }
      if (
        parseInt(mostRecentLog.temperature_2) !== 666 &&
        mostRecentLog.temperature_2 > 4
      ) {
        this.addTempIssue("hotCavity", stationId);
        console.log(`${stationId} has a cavity temperature of ${mostRecentLog.temperature_2}°C!`);
        window.alert(
          `${stationId} has a cavity temperature of ${mostRecentLog.temperature_2}°C!`
        );
      }
    } catch (err) {
      console.error("Error fetching temp values for station", stationId, err);
    }
  };

  addTempIssue = (type, stationId) => {
    this.setState((prev) => ({
      tempIssues: [{ type, serialNumber: stationId }],
    }));
    // Pass issue to the callback provided by Station.js
    this.props.setTempIssues([{ type, serialNumber: stationId }]);
  };

  handleTempCheckClick = () => {
    this.setState({ tempIssues: [] }, () => {
      const { machines } = this.props;
      this.fetchAllStationsTempStatus(machines);
      console.log("Fetching All Stations Temp Status");
    });
  };

  render() {
    const { loading, tempIssues } = this.state;
    return (
      <div className="temp-status-containter">
        <div
          className={`temp-banner ${tempIssues.length > 0 ? "" : "icon-green"}`}
          title="Temperatures Alerts"
        >
          {tempIssues.length > 0 && (
            <>
              <i
                id="alert-icon"
                className="fa-solid fa-temperature-high fa-custom"
              >
              <span className="temp-badge">{tempIssues.length}</span>
              </i>
            </>
          )}
        </div>

        {loading ? (
          <div className="loading-animation">
            <div className="fa-custom temperature-icons">
              <i className="fa-solid fa-temperature-quarter"></i>
              <i className="fa-solid fa-temperature-half"></i>
              <i className="fa-solid fa-temperature-three-quarters"></i>
              <i className="fa-solid fa-temperature-high"></i>
            </div>
            <div className="loading">Fetching temperature logs...</div>
          </div>
        ) : (
          <button className="fetch-button" onClick={this.handleTempCheckClick}>
            Fetch Temperature Alerts
          </button>
        )}
      </div>
    );
  }
}

export default TempStatus;

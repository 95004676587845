import React from "react";
import { Chart } from "react-google-charts";
import "./chartAelen.css";
class ChartAelen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // currentSort: "down",
      orders: [],
      showAveragesChart: false,
      showWeeklyAverage: true,
      toggleTotals: true,
      showCumulativeTotal: false,
    };
  }

  async componentDidMount() {
    const currentDate = new Date().toISOString().split("T")[0];
    this.setState({ orders: this.props.orders, toDate: currentDate });
  }

  changeGraph = () => {
    this.setState({ showAveragesChart: !this.state.showAveragesChart });
  };

  changeAverages = () => {
    this.setState({ showWeeklyAverage: !this.state.showWeeklyAverage });
  };

  changeTotals = () => {
    this.setState((prevState) => ({
      showCumulativeTotal: !prevState.showCumulativeTotal,
    }));
  };

  renderChartData = (movingAverage30DaysList, movingAverage7DaysList) => {
    const { showWeeklyAverage } = this.state;
    
    // Prepare chart data based on selected options
    const chartData = [
      ["Date", showWeeklyAverage ? "7-Day Average" : "30-Day Average"],
    ];
    let averageData = []
    averageData = showWeeklyAverage? movingAverage7DaysList: movingAverage30DaysList
    for (const point of averageData) {
      chartData.push([
        point[0],
        point[1]
      ]); 
    }
    return chartData;
};



  render() {
    const volumeByDate = {};

    let orders = this.props.orders;
    for (var i in orders) {
      let order = orders[i];
      let quantity_dispensed = order["quantity_dispensed"];
 
      const date = new Date(order["start_time"]).toLocaleDateString();
      if (quantity_dispensed > 0 && quantity_dispensed != NaN) {
        if (volumeByDate[date]) {
          volumeByDate[date] += parseFloat(quantity_dispensed);
        } else {
          volumeByDate[date] = parseFloat(quantity_dispensed);
        }
      } else {
        volumeByDate[date] = 0;
      }
    }

    const dateVolumeList = Object.entries(volumeByDate).map(
      ([date, volume]) => {
        const volumeAsNumber =
          typeof volume === "number" ? volume : parseFloat(volume);
        return [date, volumeAsNumber];
      }
    );
    dateVolumeList.sort((a, b) => new Date(a[0]) - new Date(b[0]));

    const dataPoints = dateVolumeList.map((entry) => ({
      date: new Date(entry[0]),
      volume: entry[1],
    }));

    dataPoints.sort((a, b) => a.date - b.date);
    const allDays = [];
    let currentDate = new Date(dataPoints[0].date);

    while (currentDate <= dataPoints[dataPoints.length - 1].date) {
      allDays.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const movingAverage30Days = {};
    let totalVolume30Days = 0;
    const allDaysVolume = [];

    // Calculate the sum of the first 30 days' volumes
    for (let i = 0; i < 30 && i < allDays.length; i++) {
      const currentDay = allDays[i];
      const entry = dataPoints.find(
        (point) => point.date.toISOString().split("T")[0] === currentDay
      );
      if (entry) {
        const volume = entry.volume / 1000;
        allDaysVolume.push(volume);
        totalVolume30Days += volume;
      } else {
        allDaysVolume.push(0);
      }
    }

    // Add the first entry to the movingAverage30Days object
    if (allDays.length >= 30) {
      movingAverage30Days[allDays[29]] = totalVolume30Days / 30;
    }

    for (let i = 30; i < allDays.length; i++) {
      const currentDay = allDays[i];
      const entry = dataPoints.find(
        (point) => point.date.toISOString().split("T")[0] === currentDay
      );
      if (entry) {
        const volume = entry.volume / 1000;
        allDaysVolume.push(volume);
        totalVolume30Days += volume;
      } else {
        allDaysVolume.push(0);
        totalVolume30Days += 0;
      }
      totalVolume30Days -= allDaysVolume[i - 30]; // Exclude volume from more than 30 days ago
      movingAverage30Days[currentDay] = totalVolume30Days / 30;
    }

    const movingAverage7Days = {};
    let totalVolume7Days = 0;

    // Calculate the sum of the first 7 days' volumes
    for (let i = 0; i < 7 && i < allDays.length; i++) {
      totalVolume7Days += allDaysVolume[i];
    }

    // Add the first entry to the movingAverage7Days object
    if (allDays.length >= 7) {
      movingAverage7Days[allDays[6]] = totalVolume7Days / 7;
    }

    for (let i = 7; i < allDays.length; i++) {
      const currentDay = allDays[i];
      totalVolume7Days += allDaysVolume[i];
      totalVolume7Days -= allDaysVolume[i - 7]; // Exclude volume from more than 7 days ago
      movingAverage7Days[currentDay] = totalVolume7Days / 7;
    }

    const result = [];
    let total = 0;
    for (let index in allDaysVolume) {
      const date = allDays[index];
      const volume = allDaysVolume[index];
      total += volume;
      result.push([
        date,
        volume,
        `<div style="white-space: nowrap; min-width: 150px;">
              <b>${date}</b><br/>
              Dispensed today: ${
                volume < 1
                  ? `${(volume * 1000).toFixed(2)} ml`
                  : `${volume.toFixed(2)} L`
              }
            </div>`,
        total,
        `<div style="white-space: nowrap; min-width: 150px;">
              <b>${date}</b><br/>
              Total Dispensed: ${
                total < 1
                  ? `${(total * 1000).toFixed(2)} ml`
                  : `${total.toFixed(2)} L`
              }
            </div>`,
      ]);
    }

    const movingAverage7DaysList = Object.entries(movingAverage7Days).map(
      ([date, volume]) => {
        const volumeAsNumber =
          typeof volume === "number" ? volume : parseFloat(volume.toFixed(1));
        return [
          date,
          volumeAsNumber,
          `<div style="white-space: nowrap; min-width: 150px;">
              <b>${date}</b><br/>
              7-day average: ${
                volumeAsNumber >= 1
                  ? `${volumeAsNumber.toFixed(2)} L`
                  : `${(volumeAsNumber * 1000).toFixed(2)} ml`
              }
            </div>`,
        ];
      }
    );

    const movingAverage30DaysList = Object.entries(movingAverage30Days).map(
      ([date, volume]) => {
        const volumeAsNumber =
          typeof volume === "number" ? volume : parseFloat(volume.toFixed(1));
        return [
          date,
          volumeAsNumber,
          `<div style="white-space: nowrap; min-width: 150px;">
              <b>${date}</b><br/>
              30-day average: ${
                volumeAsNumber >= 1
                  ? `${volumeAsNumber.toFixed(2)} L`
                  : `${(volumeAsNumber * 1000).toFixed(2)} ml`
              }
            </div>`,
        ];
      }
    );

    return (
      <>
        <div className="buttonContainer">
          <button
            onClick={this.changeGraph}
            className="averageTotalChartButton px3 mt2 btn-main"
          >
            {" "}
            {this.state.showAveragesChart ? (
              <> Show Totals</>
            ) : (
              <>Show Averages</>
            )}
          </button>
          {this.state.showAveragesChart ? (
            <button className="px3 mt2 btn-main" onClick={this.changeAverages}>
              Change to{" "}
              {this.state.showWeeklyAverage ? (
                <>30 day moving average</>
              ) : (
                <>7 day moving average</>
              )}{" "}
            </button>
          ) : (
            <button className="px3 mt2 btn-main" onClick={this.changeTotals}>
              Change to{" "}
              {!this.state.showCumulativeTotal ? (
                <>Cumulative Volume</>
              ) : (
                <>Volume by Day</>
              )}
            </button>
          )}
        </div>
        {this.state.showAveragesChart ? (
          <>
        <Chart
          chartType="LineChart"
          data={this.renderChartData(movingAverage30DaysList, movingAverage7DaysList)}
          width="100%"
          height="500px"
          options={{
            title: this.state.showWeeklyAverage ? "7-Day Moving Average" : "30-Day Moving Average",
            hAxis: {
              title: "Date",
              slantedTextAngle: 45,
              slantedText: true,
            },
            vAxis: {
              title: this.state.showWeeklyAverage ? "Volume (7-Day Average)" : "Volume (30-Day Average)",
            },
            chartArea: {
              width: "80%",
              height: "50%",
            },
            tooltip: {
              isHtml: true,
            },
            pointSize: 5,
          }}
        />
          </>
        ) : this.state.showCumulativeTotal ? (
          <Chart
            width="100%"
            height="500px"
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={[
              ["Date", "Total", { role: "tooltip", p: { html: true } }],
              ...result.map((entry) => [entry[0], entry[3], entry[4]]),
            ]}
            options={{
              title: "Cumulative Volume Over Time",
              hAxis: {
                title: "Date",
                slantedTextAngle: 45,
                slantedText: true,
              },
              vAxis: {
                title: "Cumulative Volume (L)",
              },
              series: {
                0: { color: "red" },
              },
              chartArea: {
                width: "80%",
                height: "50%",
              },
              tooltip: {
                isHtml: true,
              },
            }}
          />
        ) : (
          <Chart
            width="100%"
            height="500px"
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={[
              ["Date", "Volume", { role: "tooltip", p: { html: true } }],
              ...result.map((entry) => [entry[0], entry[1], entry[2]]),
            ]}
            options={{
              title: "Total Volume Dispensed by Date",
              hAxis: {
                title: "Date",
                slantedTextAngle: 45,
                slantedText: true,
              },
              vAxis: {
                title: "Volume (L)",
              },
              chartArea: {
                width: "80%",
                height: "50%",
              },
              tooltip: {
                isHtml: true,
              },
            }}
          />
        )}
      </>
    );
  }
}

export default ChartAelen;

import React from "react";
import "./Overwatch.css";

class Overwatch extends React.Component {
  categorizeIssues(issueDetails) {
    const offlineIssues = [];
    const unavailableIssues = [];
    const lowVolumeIssues = [];
    const temp1Issues = [];
    const temp2Issues = [];

    issueDetails.forEach((issue) => {
      switch (issue.type) {
        case "offline":
          offlineIssues.push(issue);
          break;
        case "unavailableProducts":
          unavailableIssues.push(issue);
          break;
        case "lowVolumeProducts":
          lowVolumeIssues.push(issue);
          break;
        case "hotFridge":
          temp1Issues.push(issue);
          break;
        case "hotCavity":
          temp2Issues.push(issue);
          break;
        default:
          break;
      }
    });

    return {
      offlineIssues,
      unavailableIssues,
      lowVolumeIssues,
      temp1Issues,
      temp2Issues,
    };
  }

  renderIssues(issues, title, icon) {
    if (issues.length === 0) {
      return null;
    }
    return (
      <>
        <h3>
          {title} <i class={icon}></i>
        </h3>
        {issues.map((issue, index) => (
          <div key={index} className="issue-detail">
            {issue.serialNumber}
            {issue.count
              ? ` - ${issue.count} ${
                  issue.count === 1 ? "product" : "products"
                }`
              : ""}
          </div>
        ))}
      </>
    );
  }

  render() {
    const { issueDetails, onClose } = this.props;
    const {
      offlineIssues,
      unavailableIssues,
      lowVolumeIssues,
      temp1Issues,
      temp2Issues,
    } = this.categorizeIssues(issueDetails);
    const noIssuesFound = offlineIssues.length === 0 && unavailableIssues.length === 0 && lowVolumeIssues.length === 0 && temp1Issues.length === 0 && temp2Issues.length === 0;

    return (
      <div className="overwatch-container">
        <div className="overwatch-content">
          {this.renderIssues(
            offlineIssues,
            "Offline Stations",
            "fa-solid fa-ban"
          )}
          {this.renderIssues(
            unavailableIssues,
            "Unavailable Products",
            "fa-solid fa-triangle-exclamation"
          )}
          {this.renderIssues(
            lowVolumeIssues,
            "Low Volume Products",
            "fa-solid fa-battery-quarter"
          )}
          {this.renderIssues(
            temp1Issues,
            "Hot Fridge Alerts",
            "fa-solid fa-temperature-high"
          )}
          {this.renderIssues(
            temp2Issues,
            "Hot Cavity Alerts",
            "fa-solid fa-dumpster-fire"
          )}
          <button onClick={onClose} id="overwatch-close">
            {noIssuesFound ? <>No Issues Found</> : <>Close</>}
          </button>
        </div>
      </div>
    );
  }
}

export default Overwatch;

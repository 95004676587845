import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import React from 'react';

const Public_key = "pk_live_51JPukcCAbcReKK0FxjJTODn5PCxLsJUvzrwBBIyC7A4RYzMVJdvZG3UU6tcZTud0RCMn1658aNtF3tlNjDAXIZQT009FnowEkc"
const stripePromise = loadStripe(Public_key)
export default function StripeContainer(props){
    return(
        
        <div>
            <Elements stripe={stripePromise}>
                <PaymentForm props/>
            </Elements>
        </div>
    )

}
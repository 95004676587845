import { CardCvcElement, CardExpiryElement, CardNumberElement,useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import './common.css'
import React, { useState, useEffect } from 'react'
import {Route,Navigate} from "react-router-dom";
import Row from "./Row";
import FormField from "./FormField";
import logoIcon from '../Logos/Soapstand-icon.png'


const CARD_OPTIONS = {
    style: {
        base: {
            iconColor: '#5E8680',
            color: '#fff',
            fontWeight: '700',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '3.5rem',
            fontSmoothing: 'antialiased',
            lineHeight:'1',
            ':-webkit-autofill': {
              color: '#5E8680',
            },
            '::placeholder': {
              color: '#b2d1cc',
              fontWeight: '400',
              fontSize:'3rem',
            }
      },
    }
}



const get_info =async ()=>{
    var oid = new URLSearchParams(window.location.search).get("oid")
    var orderInfo = await axios({
        method: 'GET',
        url: `/api/order/get_order_info/${oid}`
    })
    var price = parseInt(parseFloat(orderInfo['data']['price']) * 100);
    const productId = orderInfo.data.productId;
    const firstUnderscoreIndex = productId.indexOf('_');
    const secondUnderscoreIndex = productId.indexOf('_', firstUnderscoreIndex + 1);

    const beforeSecondUnderscore = productId.slice(0, secondUnderscoreIndex);
    const afterSecondUnderscore = productId.slice(secondUnderscoreIndex + 1);
    let product_id = afterSecondUnderscore
        
    const mid = orderInfo['data']['MachineSerialNumber'];
    var size = orderInfo['data']['size']
    let target = await axios({
        method: 'GET',
        url: `/api/machine/get_machine_info/${mid}`
    })
    const updateOrderStatusToInProgress = await axios.post("/api/order/order_in_progress",{
        orderId: oid
    })
    console.log(updateOrderStatusToInProgress)
    var pid="";
    if(size == "s"){
        size = "size_small"
        pid = "pid_small"
    }
    else if(size == "m"){
        size = "size_medium"
        pid = "pid_medium"
    }
    else if(size == "c"){
        size = "size_custom_max"
        pid = ""
    }
    else{
        size = "size_large"
        pid = "pid_large"
    }
    
    if((target['data']['client'])==''){
        let data = await axios({
            method: 'GET',
            url: `/api/product/get_product_info/${product_id}`
        })
        pid = data['data'][pid]
        return {'price': price, 'size': size, 'pid' : pid,'product_data': data['data'], paymentFlow: 'Internal', machine_id: mid, orderId: oid}    
    }
    else{
        product_id = orderInfo['data']['productId'];
        let data = await axios({
            method: 'GET',
            url: `/api/product/get_product_info/${product_id}`
        })
        pid = data['data'][pid]
        let target_url = target['data']['target_url']
        target_url = target_url.replace('${product_id}', pid)
        target_url = target_url.replace('${oid}', oid)
        
        return {'target': target_url, paymentFlow: 'External','price': price, 'size': size, 'pid' : pid,'product_data': data['data'], machine_id: mid, orderId: oid}
    }
    }
   
const updateTransactionInTable = async (stripeData)=>{
    let tableUpdated = await axios({
        method: "POST",
        url: "/api/machine_data/updateTransaction",
        data: stripeData
    })
    if(tableUpdated['status']==200){
        return true
    }
    else{
        return false
    }
    }



export default function PaymentForm(props) {
    const [success, setSuccess ] = useState(false)
    const [amount, setAmount] = useState(-1)
    const [currency, setCurrency] = useState('CAD')
    const [product, setProduct] = useState({})
    const [transactionData, setTransactionData] = useState({})
    const stripe = useStripe()
    const elements = useElements()
    const [paymentFlow, setPaymentFlow] = useState('')
    // const history = useHistory()
    // const [showLogin, setShowLogin] = useState(false)
    // const subscribedUser= props['props']
    const [orderId, setOrderId] = useState('')
    const [stationId, setStationId] = useState('No station id')

    // const user =  AuthService.getCurrentUser()
    // if(user){
    //     history.push(`/subscriptions${history.location.search}`)
    // }
    

    useEffect(()=>{   
        get_info().then((data)=>{
            var transaction_data = {
                orderId: data['orderId'],
                price: data['price']/100,
                MachineSerialNumber: data['machine_id'],
                productId: data['product_data']['productId'],
                contents: data['product_data']['name'],
                quantity_requested: data['quantity_requested'],
                size_unit: data['product_data']['size_unit'],
            }
            if(data['paymentFlow']== 'External'){
                setPaymentFlow(data['target'])
            }
            setOrderId(data['orderId'])
            setAmount(data['price'])
            setStationId(data['machine_id'])
            setCurrency(data['product_data']['currency'])
            setProduct({
                name: data['product_data']['name'].replace("<br>",""),
                target_vol : data['product_data'][data['size']],
                size_unit: data['product_data']['size_unit'],
                image_url: data['product_data']['image_url']
            })
            setTransactionData(transaction_data)
            console.log(transaction_data)

        });
    }, [])


    if(paymentFlow){
        window.location.href = paymentFlow;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement)
        })
        if(!error && amount!=0.00) {
        try {
            
            const price = amount
            const {id} = paymentMethod
            const currencyType = currency
            const orderId = new URLSearchParams(window.location.search).get("oid")
            const response = await axios.post("/api/payment", {
                amount: price,
                id: id,
                currency : currencyType,
                orderId: orderId,
                stationId: stationId
            })
            
            if(response.data.success) {
                let max_retries = 3
                let number_of_tries = 0
                let checkTableUpdated = false
                while(!checkTableUpdated && number_of_tries < max_retries){
                    checkTableUpdated = await updateTransactionInTable({'order':transactionData, payment: response.data})
                    number_of_tries+=1
                }
                if(checkTableUpdated == true){
                    setSuccess(true)
                }
                else{
                    setSuccess(false)
                    console.log("Some Error occured")
                    alert("Please try again! There is some error on our end")
                }
            }
            else{
                alert(response.data.message)
            }

        } catch (error) {
            console.log("Error", error)
            alert("Please try again! Payment failed")
        }
    } else {
        console.log(error.message)
        alert(error.message)

    }
}

const updateFreeOrder = async (e)=>{
    e.preventDefault();
    let tableUpdated = await axios({
        method: "POST",
        url: "/api/order/update_free_order",
        data: {orderId: new URLSearchParams(window.location.search).get("oid")}
    })
    if(tableUpdated.status == 200){
        setSuccess(true)
    }
    else{
        setSuccess(false)
        console.log("Some Error occured")
        alert("Please try again! There is some error on our end")
    }
}



    return (
        <div className="productInfoPage">  
            <div className="purchasedProduct-warning">If the <span className="bold">in-progress page</span> does not appear on the station screen, please do not proceed with the payment</div>
            <div className="purchasedProduct-infoContainer" >
                <h3 className="purchasedProduct-title">You are buying</h3>
                <div className="purchasedProduct-container">
                    <div className="purchasedProduct-imageContainer">
                        {(() => {

                                return (
                                    <>{product.image_url ?
                                        <img className="purchasedProduct-image" src={product.image_url} alt="purchased product image"></img>
                                        :
                                        <img className="purchasedProduct-imageUnavailable" src={logoIcon}></img>}
                                    </>
                                )
                        
                        })()}
                        {/* <img className="purchasedProduct-imageIcon" src={soapIcon} alt={soapIcon}></img> */}
                    </div>
                    <div className="purchasedProduct-info">
                        <div className="purchasedProduct-name">{product.name}</div>
                        <div className="purchasedProduct-volumeContainer">
                            <div className="purchasedProduct-volume">Volume:</div> 
                            <div className="purchasedProduct-volumeAmount">{product.target_vol} {product.size_unit}</div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="purchasedProduct-totalContainer">
                    <div className="purchasedProduct-total">Total amount:</div> 
                    <div className="purchasedProduct-totalAmount">${parseFloat(amount/100).toFixed(2)}</div>
                </div>
                {/* <p className="amountToPay"> Amount to Pay: ${parseFloat(amount/100).toFixed(2)}</p> */}
            
            {!success ? 
            <div class="paymentForm">
                {amount == 0 ?
                    <form onSubmit={updateFreeOrder} id="paymentCard">
                        <p className="paymentCard-free">
                            This order is free for you!! Enjoy<br/>
                        </p>
                        <button className="btn btn-main btn-clickAndSave">Click & Start Dispensing</button> 
                    </form>
                :
                    <form onSubmit={handleSubmit} id="paymentCard">
                        <Row>
                            <FormField className="formField" name="Card Number" label="Card Number"></FormField>
                            <CardNumberElement  options={CARD_OPTIONS}/>
                        </Row>
                        <div className="card-expiry-cvc">
                            <Row className="card-expiry">
                                <FormField name="Expiry Date" label="Expiry Date"></FormField>
                                <CardExpiryElement  options={CARD_OPTIONS}/>
                            </Row>
                            <Row className='card-cvc'>
                                <FormField name="CVC" label="CVC"></FormField>
                                <CardCvcElement  options={CARD_OPTIONS}/>
                            </Row>
                        </div>
                        <br/>
                        <button className="payNow">Pay Now</button> 
                    </form>
                }
            </div>
            :
            <div>
                <h2>Please dispense <i>{product.name}</i></h2>
            </div> 
            }   
        </div>
        </div>
    )
}
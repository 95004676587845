import React from "react";
// import { Progress } from 'semantic-ui-react';
import "./Product.css";
import EditProduct from "../Product/EditProduct";
// import axios from "axios";
// import authHeader from "../Authentication/auth-header";

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editProduct: false,
    };
  }
  editProduct = () => {
    this.setState({ editProduct: !this.state.editProduct });
  };

  render() {
    let product = this.props.product;
    let percentage = parseInt(
      (100 * product.volume_remaining) / product.volume_full
    );
    let bar_color = "green";
    let volume_remaining = product.volume_remaining;
    let volume_remaining_unit = product.size_unit;
    let volume_full = product.volume_full;
    let volume_full_unit = product.size_unit;
    if (volume_remaining_unit === "ml" && volume_remaining > 1000) {
      volume_remaining = parseFloat(volume_remaining / 1000).toFixed(2);
      volume_remaining_unit = "L";
    }
    if (volume_full_unit === "ml" && volume_full > 1000) {
      volume_full = volume_full / 1000;
      volume_full_unit = "L";
    }
    if (percentage < 15) {
      bar_color = "red";
    } else if (percentage < 35) {
      bar_color = "yellow";
    }

    let orders = this.props.machine.Orders;
    const filteredOrders = orders.filter(
      (order) => order.productId === this.props.product.productId
    );
    const volumeByDate = {};
    filteredOrders.forEach((order) => {
      const date = new Date(order.start_time).toLocaleDateString();
      const quantity = parseFloat(order.quantity_dispensed);
      if (!isNaN(quantity)) {
        volumeByDate[date] = (volumeByDate[date] || 0) + quantity;
      }
    });

    const dateVolumeList = Object.entries(volumeByDate).map(
      ([date, volume]) => [new Date(date), volume]
    );
    dateVolumeList.sort((a, b) => a[0] - b[0]);

    const dataPoints = dateVolumeList.map(([date, volume]) => ({
      date,
      volume,
    }));
    const daysSinceStart =
      dataPoints.length > 0
        ? (new Date() - dataPoints[0].date) / (1000 * 3600 * 24)
        : 0;

    const sumOfQuantity = filteredOrders.reduce((sum, order) => {
      const quantity = parseFloat(order.quantity_dispensed);
      return !isNaN(quantity) ? sum + quantity : sum;
    }, 0);
    let averageOver30Days =
      daysSinceStart > 30 ? sumOfQuantity / 30 : sumOfQuantity / daysSinceStart;
    if (isNaN(averageOver30Days)) {
      averageOver30Days = 0;
    }
    const calculatedValue = Math.round(
      this.props.product.volume_remaining / (averageOver30Days + 1)
    );

    const displayValue =
      calculatedValue > 60 ? "> 2 months" : "~ " + calculatedValue + " days";

    return (
      <>
        {this.state.editProduct && (
          <EditProduct
            handleClose={this.editProduct}
            serialNumber={this.props.product.MachineSerialNumber}
            product={this.props.product}
            machine={this.props.machine}
          />
        )}
        <div className="productContainer px1 table-bg-ev clearfix">
          {this.props.product.slot}
          <div className="productName col-5">
            {product.name.replace("<br>", " ")}
          </div>

          <div className="bar-width m0 py1 px1 col-2">
            {/* <Progress percent={percentage}  color={bar_color}>{parseFloat(volume_remaining).toFixed(2)} {volume_remaining_unit}/{volume_full} {volume_full_unit}</Progress> */}
            {product.size_unit == "oz"
              ? parseFloat(product.volume_remaining / 29.5735).toFixed(0)
              : parseFloat(product.volume_remaining).toFixed(0)}{" "}
            {product.size_unit}
          </div>
          <div className="px2 col-3">
            {percentage}% ({displayValue})
          </div>
          <div className="editProduct-btn col-1">
            {this.props.editProductsMode && (
              <i class="edit icon" type="button" onClick={this.editProduct}></i>
            ) 
            // : (
            //   <div className="productId">{product.productId}</div>
            // )
            }
          </div>
        </div>
      </>
    );
  }
}

export default Product;

import React from "react";
import Button from "react-bootstrap/Button";
import "./Login.css";
import AuthService from './AuthService';
import { useNavigate } from "react-router-dom";


export default function Logout() {
    const navigate = useNavigate()
  
  
  function handleSubmit(event){
    event.preventDefault();
    AuthService.logout()
    navigate("/")
    // window.location.reload()
  }

  return (
    <div>
        <Button onClick={handleSubmit} id="logout">LogOut</Button>
    </div>
  );
}
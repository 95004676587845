import React, { useState } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import AuthService from "./AuthService";
// import { useHistory } from "react-router-dom";
import "./OTP.css";

function OTPVerification() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  function validateForm() {
    return (
      email.length > 0 ||
      (otp.length > 0 &&
        password.length > 0 &&
        confirmPassword.length > 0 &&
        password === confirmPassword)
    );
  }

  const handleGenerateOTP = async (e) => {
    e.preventDefault();
    setShowError(false);
    setShowSuccess(false);

    try {
      const response = await axios.post("/api/user/generateOtp", { email });
      console.log(response.data, `OTP sent to ${email}`);

      if (!response.data.success) {
        if (response.status === 404) {
          // alert("Email id does not exists.");
        } else if (response.status === 500) {
          // alert("Internal Server Error: Unable to send OTP");
        } else {
          console.error(`Unexpected error: ${response.status}`);
        }
      } else {
        setSuccessMessage("Please check your email");
        setShowSuccess(true);
        setShowVerify(true);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      setErrorMessage(error.response.data.message);
      setShowError(true);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setShowSuccess(false);
    setShowError(false);

    if (otp.length === 0) {
      setErrorMessage("Please enter your OTP");
      setShowError(true);
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setShowError(true);
      return;
    }
    if (password.length < 6 || !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setErrorMessage("Invalid password");
      setShowError(true);
      return;
    }

    try {
      // Send the OTP to the backend for verification
      const response = await axios.post("/api/user/verifyOtp", {
        otp,
        email,
        password,
      });
      if (response) {
        setSuccessMessage("Your password has been changed");
        setShowSuccess(true);
        window.location.reload();
      } else {
        alert("Otp does not match");
      }
    } catch (err) {
      console.error("Error verifying OTP:", err);
      setErrorMessage(err.response.data.message);
      setShowError(true);
    }
  };

  return (
    <div className="OTP">
      <div className="OTP">
        {showError && (
          <div className="alert-container">
            <div className="alert-message alert-error">{errorMessage}</div>
          </div>
        )}
        {showSuccess && (
          <div className="alert-container">
            <div className="alert-message alert-success">{successMessage}</div>
          </div>
        )}
      </div>

      {!showVerify ? (
        <>
          <div className="otp-title">one-time password reset</div>
          <Form className="generate-otp" onSubmit={handleGenerateOTP}>
            <Form.Group size="lg" controlId="email" id="email">
              <Form.Control
                autoFocus
                type="email"
                value={email}
                placeholder="Enter email here"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Button
              block
              size="lg"
              type="submit"
              disabled={!validateForm()}
              id="generate-otp-btn"
            >
              Send OTP to Email address
            </Button>
          </Form>
        </>
      ) : (
        <>
          <div className="otp-title">
            reset password
            <br />
            <h4>
              Password must be at least 6 characters long and include a special
              character
            </h4>
          </div>
          <Form className="verify-otp" onSubmit={handleVerifyOTP}>
            <Form.Group size="lg" controlId="text" id="otp">
              <Form.Control
                autoFocus
                type="text"
                value={otp}
                placeholder="Enter your OTP here"
                onChange={(e) => setOtp(e.target.value)}
              />
            </Form.Group>
            <Form.Group size="lg" controlId="text" id="password">
              <Form.Control
                autoFocus
                type="password"
                value={password}
                placeholder="Enter your new password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group size="lg" controlId="text" id="confirmPassword">
              <Form.Control
                autoFocus
                type="password"
                value={confirmPassword}
                placeholder="Confirm your new password"
                onChange={(e) => setconfirmPassword(e.target.value)}
              />
            </Form.Group>

            <Button
              block
              size="lg"
              type="submit"
              disabled={!validateForm()}
              id="verify-otp-btn"
            >
              Change password
            </Button>
          </Form>
        </>
      )}
    </div>
  );
}

export default OTPVerification;

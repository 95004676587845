import React, { Component } from "react";
import axios from "axios";
import authHeader from "../Authentication/auth-header";
import { CSVLink } from "react-csv";
// import AuthService from '../Authentication/AuthService';
import "./Order.css";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      orders: [],
      fromDate: "",
      toDate: "",
      currentSort: "down",
    };
  }

  async componentDidMount() {
    this.get_user_machines();
  }

  get_user_machines = () => {
    axios({
      method: "get",
      url: "/api/user/get_my_orders",
      headers: authHeader(),
    }).then((response) => {
      this.setState({ orders: response.data });
    });
  };

  getRecords = (e) => {
    e.preventDefault();
    let toDate = new Date(this.state.toDate);
    toDate.setDate(toDate.getDate() + 1);

    let adjustedToDate = toDate.toISOString().split("T")[0];

    let payload = {
      fromDate: this.state.fromDate,
      toDate: adjustedToDate,
    };
    axios({
      method: "post",
      url: "/api/user/get_custom_date_orders",
      headers: authHeader(),
      data: payload,
    }).then((response) => {
      this.setState({ orders: response.data });
    });
  };

  onSortChange = () => {
    const { currentSort } = this.state;
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "down";
    this.setState({
      currentSort: nextSort,
    });
  };

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    const sortTypes = {
      up: {
        class: "sort-up",
        fn: (a, b) => new Date(a.start_time) - new Date(b.start_time),
      },
      down: {
        class: "sort-down",
        fn: (a, b) => new Date(b.start_time) - new Date(a.start_time),
      },
      default: {
        class: "sort",
        fn: (a, b) => a,
      },
    };
    const { currentSort } = this.state;
    let exportOrder = [];
    for (var i in this.props.orders) {
      let order = this.props.orders[i];
      let quantity_requested = order["quantity_requested"];
      let quantity_dispensed = order["quantity_dispensed"];
      if (this.props.orders[0].size_unit === "oz") {
        quantity_dispensed = (quantity_dispensed / 29.5735).toFixed(2);
        quantity_requested = (quantity_requested / 29.5735).toFixed(2);
      }
      var individualOrder = {
        location: this.props.location,
        start_time: order["start_time"],
        contents: order["contents"],
        quantity_requested: quantity_requested,
        quantity_dispensed: quantity_dispensed,
        price: order["price"],
        timestamp: Math.floor(Date.parse(order["start_time"]) / 1000),
        orderStatus: order["orderStatus"],
        productId: order["productId"],
      };
      exportOrder.push(individualOrder);
    }
    let headers = [
      { label: "location", key: "location" },
      { label: "start_time", key: "start_time" },
      { label: "contents", key: "contents" },
      { label: "quantity_requested", key: "quantity_requested" },
      { label: "quantity_dispensed", key: "quantity_dispensed" },
      { label: "price", key: "price" },
      { label: "timestamp", key: "timestamp" },
      { label: "orderStatus", key: "orderStatus" },
      { label: "productId", key: "productId" },
    ];
    return (
      <>
        <div className="allOrders">
          <div className="ownerInfo">transactions</div>
          <form
            onSubmit={this.getRecords}
            className="transaction-reports"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="date-range-input">
              <label htmlFor="fromDate" className="allOrders-dateLabel">
                From Date:
              </label>
              <input
                type="date"
                id="fromDate"
                value={this.state.fromDate}
                onChange={(e) => this.setState({ fromDate: e.target.value })}
                className="dateInput"
                required
              />
            </div>
            <div className="date-range-input">
              <label htmlFor="toDate" className="allOrders-dateLabel">
                To Date:
              </label>
              <input
                type="date"
                id="toDate"
                value={this.state.toDate}
                onChange={(e) => this.setState({ toDate: e.target.value })}
                className="dateInput"
                required
              />
            </div>
            <button type="submit" className="button-getReport">
              Custom records
            </button>
          </form>
          <div className="btn-groups">
            <button onClick={this.onSortChange} className="btn btn-main">
              Sort By Date
            </button>{" "}
            &ensp;
            <CSVLink
                data={exportOrder}
                headers={headers}
                filename="orders-data"
                style={{ textDecoration: "none", color: "#fff", display: "inline-block", textAlign: "center", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                className="btn btn-main"
              >
                Export Data
              </CSVLink>
          </div>
          <div className="transactionTable">
            <table id="orders">
              <tbody>
                <tr key={"Heading"} class="heading">
                  <td>location</td>
                  <td>date</td>
                  <td>time</td>
                  <td>product</td>
                  <td>quantity requested </td>
                  <td>quantity dispensed</td>
                  <td>price($)</td>
                </tr>

                {this.state.orders
                  .sort(sortTypes[currentSort].fn)
                  .map((order, index) => {
                    let {
                      MachineSerialNumber,
                      orderId,
                      start_time,
                      contents,
                      quantity_requested,
                      quantity_dispensed,
                      price,
                      size_unit,
                    } = order;
                    const date_time = start_time.split("T");
                    const date = date_time[0];
                    let time = date_time[1].split(".");
                    time = time[0];
                    if (size_unit === "oz") {
                      quantity_requested =
                        parseFloat(quantity_requested) / 29.5735;
                      quantity_dispensed =
                        parseFloat(quantity_dispensed) / 29.5735;
                    }
                    quantity_requested =
                      parseFloat(quantity_requested).toFixed(1);
                    quantity_dispensed =
                      parseFloat(quantity_dispensed).toFixed(2);
                    if (contents) {
                      contents = contents.replace("<br>", " ");
                    }

                    price = parseFloat(price).toFixed(2);
                    return (
                      <tr key={orderId}>
                        <td>{MachineSerialNumber}</td>
                        <td>{date}</td>
                        <td>{time}</td>
                        <td>{contents}</td>
                        <td>{quantity_requested}</td>
                        <td>{quantity_dispensed}</td>
                        <td>{price}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <button onClick={this.scrollToTop} id="scroll-to-top">
            <i class="fa-solid fa-arrow-up"></i>
          </button>
        </div>
      </>
    );
  }
}

export default Order;

import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';


import axios from 'axios';
import authHeader from '../Authentication/auth-header';
import ViewCoupon from './viewCoupons';

class ModifyCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
        coupons: [],
        selectedCoupons: [],
        selectAllCoupons: false,
        stations: [],
        selectedStations: [],
        selectAllStations: false,
        discountType: 'single',
        times: 1,
        percentage: '5',
        couponInputType: 'userDefined',
        active: true,
        filteredListCoupons:[],
        filteredListStations:[],
        searchQuery: '',
        isOpen: false,
        showCoupons: true
    };
  }

  async componentDidMount(){
    this.get_all_coupons();
    this.get_all_stations_and_coupons();
}



get_all_coupons = () =>{
    axios({
        method: 'get',
        url: '/api/coupon/get_all_coupons',
        headers: authHeader()
    }).then((response)=>{
        this.setState({coupons: response.data, filteredListCoupons: response.data})
    })}
get_all_stations_and_coupons = ()=>{
    axios({
      method: 'get',
      url: `/api/user/get_users_machine_ids`,
      headers: authHeader()
      }).then(response=>{
        this.setState({stations: response["data"]["machineCoupons"], filteredListStations: response["data"]["machineCoupons"]})
      })
    }
  
  handleOptionChange = (option) => {
    const { selectedCoupons } = this.state;

    if (selectedCoupons.includes(option)) {
      this.setState({
        selectedCoupons: selectedCoupons.filter((selectedOption) => selectedOption !== option),
      });
    } else {
      this.setState({
        selectedCoupons: [...selectedCoupons, option],
      });
    }
  }

  handleStationChange = (option) => {
    const { selectedStations } = this.state;
    if (selectedStations.includes(option)) {
      this.setState({
        selectedStations: selectedStations.filter((selectedStation) => selectedStation !== option),
      });
    } else {
      this.setState({
        selectedStations: [...selectedStations, option],
      });
    }
  }
  handleselectAllStations = () => {
    const { filteredListStations, selectAllStations } = this.state;
    this.setState({
      selectedStations: selectAllStations ? [] : filteredListStations,
      selectAllStations: !selectAllStations,
    });
  }

  handleselectAllCouponsChange = () => {
    const { filteredListCoupons, selectAllCoupons } = this.state;
    this.setState({
      selectedCoupons: selectAllCoupons ? [] : filteredListCoupons,
      selectAllCoupons: !selectAllCoupons,
    });
  }

  handleSubmit = () => {
    const { selectedCoupons } = this.state;
  }

  handleSearch = (event) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery }, () => {
      this.filterCouponList();
    });
  };

  filterCouponList = () => {
    const { searchQuery } = this.state;
    const filteredListCoupons = this.state.coupons.filter((item) =>
      item.toLowerCase().includes(searchQuery.toLowerCase())
    );
    this.setState({ filteredListCoupons });
  };


  modifyCoupon = (event) => {
    event.preventDefault();
    axios({
            method: 'post',
            url: '/api/coupon/updateBulk',
            headers: authHeader(),
            data: this.state

        }).then((response)=>{
            if(response.status == 201){
                alert("Coupons updated")
                window.location.href = 'https://soapstandportal.com/coupon'
            }
            else{
                alert("Coupon Update failed. Please try again")
            }
            this.setState({
                couponCode: '',
                discountType: 'single',
                times: 1,
                percentage: '5',
                couponInputType: 'userDefined',
                numOfCoupons: 1,
                startDate: '',
                endDate: ''
            })
        })
  };

  archiveSelected=(event)=>{
    event.preventDefault();
     axios({
            method: 'post',
            url: '/api/coupon/archive_selected',
            headers: authHeader(),
            data: {coupons: this.state.selectedCoupons}

        }).then((response)=>{
            if(response.status == 200){
                alert("Coupons archived")
            }
        })
  }
  handleChange = (event) => {

    this.setState({ [event.target.name]: event.target.value });
  };

  toggleMenu = () =>{
    let isOpen = !this.state.isOpen
    this.setState({isOpen: isOpen})
  }

  showFilteredCoupons =()=>{
    let selectedStations = this.state.selectedStations
    let allStationCoupons = this.state.stations
    const filteredData = allStationCoupons.filter(item => selectedStations.includes(item.MachineId)).reduce((unique, item) => {
      item.CouponCodes.forEach(CouponCodes => {
        if (CouponCodes !== null && !unique.includes(CouponCodes)) {
          unique.push(CouponCodes);
        }
      });
      return unique;
    }, []);
    this.setState({coupons: filteredData, filteredListCoupons: filteredData, isOpen: false})

  }
  findIntersections = (lists)=> {
    if (lists.length === 0) {
      return [];
    }
    const referenceList = lists[0];
    const intersection = referenceList.filter(item => {
      for (let i = 1; i < lists.length; i++) {
        if (!lists[i].includes(item)) {
          return false;
        }
      }
      return true;
    });
    return intersection;
  }

  assignToStation = ()=>{
    axios({
      method: 'post',
      url: '/api/coupon/addCouponListToMachineList',
      headers: authHeader(),
      data: {couponList: this.state.selectedCoupons,MachineSerialNumberList:this.state.selectedStations}
    }).then((response)=>{
      alert(response.data)
    })}
  

  render() {
    const couponTypescoupons = [
      <option key="single" value="single">Single Use</option>,
      <option key="multiple" value="multiple">Multiple Use</option>,
      <option key="unlimited" value="unlimited">Unlimited</option>
    ];

    const statusTypecoupons = [
        <option key="true" value="true">Active</option>,
        <option key="false" value="false">Inactive</option>,
      ];
 
    const percentageDiscountcoupons = Array.from({ length: 20 }, (_, index) => {
        const value = (index + 1) * 5;
        return <option key={value} value={value}>{value}%</option>;
      });
      const { filteredListCoupons, selectedCoupons, selectAllCoupons,filteredListStations, selectAllStations, selectedStations  } = this.state;

    return (
        <>  
        <div className="box">
          <div className='addCoupon'>
            <Form >
            <button type="button" onClick={this.toggleMenu}>
            Filter coupon using Stations
              </button> <br/>
              {this.state.isOpen &&
              <>
                <div className='checkbox-container'> 
                  <input
                      type="checkbox"
                      id="selectAllStations"
                      checked={selectAllStations}
                      onChange={this.handleselectAllStations}
                      />
                  <label className='checkbox-label' htmlFor="selectAllCoupons">Select All Stations</label>
                </div>
                <div className='container'> 
                    {filteredListStations.map((station, index) => (
                    <div key={station.MachineId} className='checkbox-container'>
                        <input
                        type="checkbox"
                        id={station.MachineId}
                        value={station.MachineId}
                        checked={selectedStations.includes(station.MachineId)}
                        onChange={() => this.handleStationChange(station.MachineId)}
                        />
                        <label className='checkbox-label' htmlFor={`option${index}`}>{station.MachineId}</label>
                    </div>
                    ))}
                </div>
                <button type="button" onClick={this.showFilteredCoupons}>Select Stations</button>

                </>}
              {this.state.showCoupons &&
              <>
                <div className='checkbox-container'> 
                  <input
                    type="text"
                    placeholder="Search..."
                    value={this.state.searchQuery}
                    onChange={this.handleSearch}
                  />
                  <input
                      type="checkbox"
                      id="selectAllCoupons"
                      checked={selectAllCoupons}
                      onChange={this.handleselectAllCouponsChange}
                      />
                  <label className='checkbox-label' htmlFor="selectAllCoupons">Select All</label>
                </div>
                <div className='container'> 
                    {filteredListCoupons.map((option, index) => (
                    <div key={option} className='checkbox-container'>
                        <input
                        type="checkbox"
                        id={`option${index}`}
                        value={option}
                        checked={selectedCoupons.includes(option)}
                        onChange={() => this.handleOptionChange(option)}
                        />
                        <label className='checkbox-label' htmlFor={`option${index}`}>{option}</label>
                    </div>
                    ))}
                </div>
                </>}
              <Form.Group controlId="formBasicSelect">
                <Form.Label>Coupon Type</Form.Label>
                <Form.Control 
                  as="select"
                  name="discountType"
                  onChange={this.handleChange}
                  required
                >
                  {couponTypescoupons}
                </Form.Control>
              </Form.Group>
              {this.state.discountType === "multiple" && 
              <Form.Group controlId="numberInput">
                <Form.Label>Number of Times</Form.Label>
                <Form.Control 
                  name="times"
                  type="number"
                  value={this.state.times}
                  onChange={this.handleChange}
                  placeholder="Number of Times"
                />
              </Form.Group>}
              <Form.Group controlId="formBasicSelect">
                <Form.Label>Percentage Discount</Form.Label>
                <Form.Control 
                  as="select"
                  name="percentage"
                  onChange={this.handleChange}
                >
                  {percentageDiscountcoupons}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formBasicSelect">
                <Form.Label>Coupon status</Form.Label>
                <Form.Control 
                  as="select"
                  name="active"
                  onChange={this.handleChange}
                  required
                >
                  {statusTypecoupons}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={this.state.startDate}
                  onChange={this.handleChange}
                  
                />
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="endDate"
                  value={this.state.endDate}
                  onChange={this.handleChange}
                />
              </Form.Group>
               {this.state.isOpen &&
              <>
                <div className='checkbox-container'> 
                  <input
                      type="checkbox"
                      id="selectAllStations"
                      checked={selectAllStations}
                      onChange={this.handleselectAllStations}
                      />
                  <label className='checkbox-label' htmlFor="selectAllCoupons">Select All Stations</label>
                </div>
                <div className='container'> 
                    {filteredListStations.map((station, index) => (
                    <div key={station.MachineId} className='checkbox-container'>
                        <input
                        type="checkbox"
                        id={station.MachineId}
                        value={station.MachineId}
                        checked={selectedStations.includes(station.MachineId)}
                        onChange={() => this.handleStationChange(station.MachineId)}
                        />
                        <label className='checkbox-label' htmlFor={`option${index}`}>{station.MachineId}</label>
                    </div>
                    ))}
                </div>
                <button type="button" onClick={this.showFilteredCoupons}>Select Stations</button>

                </>} 
                <br/>
                <label>Assign the coupon to a station</label>
                <div className='container'> 
                    {filteredListStations.map((station, index) => (
                    <div key={station.MachineId} className='checkbox-container'>
                        <input
                        type="checkbox"
                        id={station.MachineId}
                        value={station.MachineId}
                        checked={selectedStations.includes(station.MachineId)}
                        onChange={() => this.handleStationChange(station.MachineId)}
                        />
                        <label className='checkbox-label' htmlFor={`option${index}`}>{station.MachineId}</label>
                    </div>
                    ))}
                </div>
              <br/>
              <Button onClick={this.assignToStation}>Assign to a station</Button>
              <Button variant="primary" type="submit" className='button' onClick={this.modifyCoupon}>
                Save Changes
              </Button>
              <Button variant="primary" type="submit" className='button' onClick={this.archiveSelected}>
                Archive Coupons
              </Button>
            </Form>
          </div>
        </div>
        </>
    )
  }
}

export default ModifyCoupon;

import React from 'react';
import axios from 'axios';



class OfflinePaymentPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            authenticationCode: ''
        }
    }

    async componentDidMount(){
        this.get_authentication_code();
    }

    get_authentication_code = () =>{

        const params = new URLSearchParams(window.location.search);
        const queryString = params.toString();
        axios({
            method: 'post',
            url: '/api/order/generateCode',
            data: queryString
            
        }).then((response)=>{
            this.setState({authenticationCode: response.data})
        })
    }

    render(){
        return (
            <>
            <div style={{ marginTop: '200px' }}>
                <h1>{this.state.authenticationCode}</h1>
            </div>
            </>
        )

    }
    
}

export default OfflinePaymentPage;
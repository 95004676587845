import React, { useState } from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from 'axios';
import authHeader from '../Authentication/auth-header';
import "./ChangePassword.css";


export default function ChangePasswordForm(){
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [error, setError] = useState('');
    
  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if ( !newPassword || !confirmNewPassword) {
      setError('All fields are required');
      alert(error)
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match');
      alert(error)
      return;
    }
    if (newPassword.length >= 6 && /[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
        setNewPassword(newPassword);
        setError('');
      } else {
        setError('Password must be at least 6 characters long and include a special character.');
        alert("Password must be at least 6 characters long and include a special character.")
        return
      }
    axios({
        method: 'post',
        url: '/api/user/updatePassword',
        headers: authHeader(),
        data:{'newPassword': newPassword}
    }).then((response)=>{
        if(response.status==201){
            alert(response.data)
            window.location.href = 'https://soapstandportal.com'
        }
        else{
            alert("Please try again")
        }
    })
    setError('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  return (
    <div>
    <div className='updatePassword'>Update Password</div>
    <Form className="ChangePassword" onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="password" id="newPassword">
          <Form.Control
            type="password"
            value={newPassword}
            placeholder="New Password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Form.Group>
        <h3>Password should be minimum 6 characters long and should include a special character (@$#..)</h3>
        <Form.Group size="lg" controlId="password" id="confirmPassword">
          <Form.Control
            type="password"
            value={confirmNewPassword}
            placeholder="Confirm New Password"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </Form.Group>

        <Button block size="lg" type="submit" id="ChangePassword-button">
          Change Password
        </Button>
      </Form>
      </div>
  );
};


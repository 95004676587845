import axios from 'axios';

class AuthService{
    login(email, password){
        return axios
            .post("/api/user/signin", {
                email, password
            })
            .then(response =>{
                if(response.data.accessToken){
                    localStorage.setItem("user", JSON.stringify(response.data))
                }
                return response.data
            });
    }

    logout(){
        localStorage.removeItem("user");
    }

    register(email,password){
        return axios.post( "/api/user/signup", {
            email,
            password
        })
    }

    getCurrentUser(){
        return JSON.parse(localStorage.getItem('user'))
    }

    checkIfTokenExpired(){
        try{
            let expiry_date = new Date(JSON.parse(localStorage.getItem('user'))["token_expiry"])
            let current_date = new Date()
            if(current_date.getTime() > expiry_date.getTime()){
                return true
            }
            return false
        }
        catch{
            return true
        }
        
    }

    checkIfUserIsDeveloper(){
        let permissions = JSON.parse(localStorage.getItem('user'))["permissions"]
        return permissions.includes("developer");
    }
    checkIfUserIsAdmin(){
        let permissions = JSON.parse(localStorage.getItem('user'))["permissions"]
        return permissions.includes("admin");
            
    }
}

export default new AuthService()
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dropdown/style.css';
import './coupons.css'
import Coupon from './coupon'
import AddCoupon from './addCoupon'
import ModifyCoupon from './modifyCoupon'
import ArchivedCoupon from './archivedCoupons';
import KlaviyoCoupon from './klaviyoCoupon'

class CouponManager extends React.Component {
    constructor(props){
        super(props)
        this.state = {     
            activeTab: 'view'
        }
    }

    handleTabClick = (tab) => {
        this.setState({ activeTab: tab });
      }
    
    render(){
        const { activeTab } = this.state;

        return (
            <div>
            <div className="tab-menu">
              <button className={activeTab === 'view' ? 'active' : ''} onClick={() => this.handleTabClick('view')}>View Coupons</button>
              <button className={activeTab === 'add' ? 'active' : ''} onClick={() => this.handleTabClick('add')}>Add New Coupon</button>
              <button className={activeTab === 'modify' ? 'active' : ''} onClick={() => this.handleTabClick('modify')}>Modify Coupons</button>
              <button className={activeTab === 'archived' ? 'active' : ''} onClick={() => this.handleTabClick('archived')}>Archived Coupons</button>
              <button className={activeTab === 'Klaviyo' ? 'active' : ''} onClick={() => this.handleTabClick('klaviyo')}>Klaviyo Coupons</button>
            </div>
            
            <div>
              {activeTab === 'view' && <Coupon/>}
              {activeTab === 'add' && <AddCoupon />}
              {activeTab === 'modify' && <ModifyCoupon />}
              {activeTab === 'archived' && <ArchivedCoupon />}
              {activeTab === 'klaviyo' && <KlaviyoCoupon />}

            </div>
          </div>
        );
    }
    
}

export default CouponManager;
import React from "react";
import "./AlertBanner.css";
import Overwatch from "./Overwatch";
// import lowVolumeIcon from "../../Assets/lowVolumeIcon.png";

class AlertBanner extends React.Component {
  state = {
    showOverwatch: false,
    issueDetails: [],
    // tempIssues: [],
  };

  collectAllIssues(machines) {
    let issueDetails = [];

    Object.values(machines).forEach((machineArray) => {
      machineArray.forEach((machine) => {
        if (!machine.active) {
          issueDetails.push({
            type: "offline",
            serialNumber: machine.serialNumber,
          });
        }
        const unavailableCount = this.unavailableProducts(machine.Products);
        if (unavailableCount > 0) {
          issueDetails.push({
            type: "unavailableProducts",
            count: unavailableCount,
            serialNumber: machine.serialNumber,
          });
        }
        const lowVolumeCount = this.lowVolumeProducts(machine.Products);
        if (lowVolumeCount > 0) {
          issueDetails.push({
            type: "lowVolumeProducts",
            count: lowVolumeCount,
            serialNumber: machine.serialNumber,
          });
        }
      });
    });

    return issueDetails;
  }

  // Collect individual issues
  collectOfflineIssues(machines) {
    let issueDetails = [];
    Object.values(machines).forEach((machineArray) => {
      machineArray.forEach((machine) => {
        if (!machine.active) {
          issueDetails.push({
            type: "offline",
            serialNumber: machine.serialNumber,
          });
        }
      });
    });
    return issueDetails;
  }
  collectUnavailableIssues(machines) {
    let issueDetails = [];
    Object.values(machines).forEach((machineArray) => {
      machineArray.forEach((machine) => {
        const unavailableCount = this.unavailableProducts(machine.Products);
        if (unavailableCount > 0) {
          issueDetails.push({
            type: "unavailableProducts",
            count: unavailableCount,
            serialNumber: machine.serialNumber,
          });
        }
      });
    });
    return issueDetails;
  }
  collectLowVolumeIssues(machines) {
    let issueDetails = [];
    Object.values(machines).forEach((machineArray) => {
      machineArray.forEach((machine) => {
        const lowVolumeCount = this.lowVolumeProducts(machine.Products);
        if (lowVolumeCount > 0) {
          issueDetails.push({
            type: "lowVolumeProducts",
            count: lowVolumeCount,
            serialNumber: machine.serialNumber,
          });
        }
      });
    });
    return issueDetails;
  }

  // Handle different click locations
  handleBannerClick = () => {
    const issueDetails = this.collectAllIssues(this.props.machines);
    this.setState({ showOverwatch: true, issueDetails });
  };
  handleOfflineClick = (event) => {
    event.stopPropagation();

    const issueDetails = this.collectOfflineIssues(this.props.machines);
    this.setState({ showOverwatch: true, issueDetails });
  };
  handleUnavailableClick = (event) => {
    event.stopPropagation();

    const issueDetails = this.collectUnavailableIssues(this.props.machines);
    this.setState({ showOverwatch: true, issueDetails });
  };
  handleLowVolumeClick = (event) => {
    event.stopPropagation();

    const issueDetails = this.collectLowVolumeIssues(this.props.machines);
    this.setState({ showOverwatch: true, issueDetails });
  };

  // ALERT COUNTING FUNCTIONS
  unavailableProducts(products) {
    return products.reduce(
      (count, product) => count + (product.volume_remaining <= 0 ? 1 : 0),
      0
    );
  }
  lowVolumeProducts(products) {
    return products.reduce((count, product) => {
      const percentage = parseInt(
        (100 * product.volume_remaining) / product.volume_full
      );
      return count + (percentage > 0 && percentage < 10 ? 1 : 0);
    }, 0);
  }
  offlineStations(machines) {
    return Object.values(machines).reduce((count, machineArray) => {
      return (
        count +
        machineArray.reduce(
          (subCount, machine) => subCount + (!machine.active ? 1 : 0),
          0
        )
      );
    }, 0);
  }

  // setTempIssues = (tempIssues) => {
  //   this.setState({ tempIssues });
  // };

  render() {
    const { showOverwatch, issueDetails } = this.state;
    const { machines, tempIssues } = this.props;

    const allIssues = issueDetails.concat(tempIssues);


    let totalUnavailable = 0;
    let totalLowVolume = 0;

    Object.values(machines).forEach((machineArray) => {
      machineArray.forEach((machine) => {
        totalUnavailable += this.unavailableProducts(machine.Products);
        totalLowVolume += this.lowVolumeProducts(machine.Products);
      });
    });

    let totalOffline = this.offlineStations(machines);

    const showBadgeOffline = totalOffline > 0;
    const showBadgeUnavailable = totalUnavailable > 0;
    const showBadgeLowVolume = totalLowVolume > 0;

    return (
      <>
        <div className="alert-banner" onClick={this.handleBannerClick}>
          <span
            className={`alert-icon ${!showBadgeOffline ? "icon-green" : ""}`}
            onClick={this.handleOfflineClick}
            title="Offline Stations"
          >
            {showBadgeOffline && <span className="badge">{totalOffline}</span>}
            <div className="no-wifi">
              <i
                className={`fa-solid fa-wifi ${
                  showBadgeOffline ? "fa-2x" : "fa-3x"
                }`}
              ></i>
              {showBadgeOffline && <i className="fa-solid fa-slash fa-2x"></i>}
            </div>
          </span>
          <span
            className={`alert-icon ${
              !showBadgeUnavailable ? "icon-green" : ""
            }`}
            onClick={this.handleUnavailableClick}
            title="Unavailable Products"
          >
            {showBadgeUnavailable && (
              <span className="badge">{totalUnavailable}</span>
            )}
            <i
              className={`fa-solid fa-triangle-exclamation ${
                showBadgeUnavailable ? "fa-2x" : "fa-3x"
              }`}
            ></i>
          </span>
          <span
            className={`alert-icon ${!showBadgeLowVolume ? "icon-green" : ""}`}
            onClick={this.handleLowVolumeClick}
            title="Low Volume Products"
          >
            {showBadgeLowVolume && (
              <span className="badge">{totalLowVolume}</span>
            )}
            <i
              class={`${
                showBadgeLowVolume
                  ? "fa-solid fa-battery-quarter fa-2x"
                  : "fa-solid fa-battery-full fa-3x"
              }`}
            ></i>
            {/* <img id="low-volume-icon" src={lowVolumeIcon} alt="low volume icon"></img> */}
          </span>
        </div>
        {showOverwatch && (
          <Overwatch
            issueDetails={allIssues}
            onClose={() => this.setState({ showOverwatch: false })}
          />
        )}
      </>
    );
  }
}

export default AlertBanner;
